import * as Papa from 'papaparse'
import { isArray } from 'util'
import {
  IPayment,
  TransactionStatus,
  Province,
  PaymentQueueStatus,
  IBaseCategory,
  ITransaction,
  GiftCardPaymentTypeIds,
  ILocation,
  IPaymentQueueWithExternalSource,
  CreatePayment,
  ICompany,
  IDevice,
  CreatePaymentLine,
  IPaymentQueueLine,
  PaymentQueueOrderType,
  PaymentTypeIds,
  CreateTransaction,
  CreateGiftCardTransaction,
  IInventoryLog,
} from '@getgreenline/homi-shared'
import moment from 'moment'
import { groupBy, isNull } from 'lodash'
import { ParkedSaleStore } from '../containers/Dashboard/DashboardPayments/ParkedSales/ParkedSaleStore'
import { CloudinaryDetailsResponse, CurrentCompanyStore } from '../stores/CurrentCompanyStore'
import { OptionProps } from 'antd/lib/select'
import { SelectProps } from 'antd-v4/lib/select'
import { CloudinaryResponse } from '../components/ImageUploadModal'
import { CLOUDINARY, BLAZE_COMPANY } from '../constants/general'
import { POSStore } from '../stores/POSStore'
import { CreateGiftCardPaymentLine } from '@getgreenline/homi-shared/dist/models/Payments/CreatePaymentLine'
import isEmail from 'validator/lib/isEmail'
import { OrderedExportDataFields } from '../constants/OrderedExportDataFields'
import { ProductModels } from '@getgreenline/products'
import {
  CannabinoidMetaDataItem,
  CreateChildProduct,
  CreateProduct,
  ProductMetaData,
} from '../containers/Dashboard/DashboardProducts/AddProduct/ProductStore'
import { ILocationContract } from '@getgreenline/locations/build/models'

interface IEmailDetail {
  mailto: string
  cc?: string
  subject?: string
  body?: string
}

export interface UploadFile {
  lastModified: number
  lastModifiedDate: Date
  name: string
  preview: string
  size: number
  type: string
  webkitRelativePath: string
}

export interface DropzoneFile {
  lastModified: number
  lastModifiedDate: Date
  name: string
  originFileObj: UploadFile
  percent: string
  size: number
  type: string
  status: string
  uid: string
}

export interface PackingSlipData {
  company?: ICompany
  location?: ILocation
  device?: IDevice
  paymentQueue?: IPaymentQueueWithExternalSource
  customerName?: string
  customerPhone: string | null
  paymentData?: CreatePayment | IPaymentQueueWithExternalSource
  orderStatus: PaymentQueueOrderType | undefined
}

export interface TPOSData {
  paymentQueue?: IPaymentQueueWithExternalSource
  posStore?: POSStore
  payment?: CreatePayment | IPaymentQueueWithExternalSource
}

export type TPackingSlipData<T extends ParkedSaleStore | TPOSData> = T extends ParkedSaleStore
  ? ParkedSaleStore
  : TPOSData

export class GrPrice {
  private static convertNumberPriceToStringPrice(rawPrice: number) {
    const absolutePrice = Math.abs(rawPrice)
    const twoDecimalStringPrice = GrPrice.convertDecimalString(absolutePrice)
    // Add $ sign
    const dollarString = `$${twoDecimalStringPrice}`

    return rawPrice < 0 ? `-${dollarString}` : dollarString
  }

  static convertCentToDollar(centValue: number) {
    return `$${(centValue / 100).toFixed(2)}`
  }

  static convertDollarToCent(dollarValue: string) {
    const num = parseFloat(dollarValue.replace('$', ''))
    return Math.round(num * 100)
  }

  static convertDollarToDollar(dollarValue: number) {
    return GrPrice.convertNumberPriceToStringPrice(dollarValue)
  }

  static convertDollarToShortFormDollar = (rawPrice: number) => {
    switch (true) {
      case rawPrice > 1000000000:
        return GrPrice.convertDollarToDollar(rawPrice / 1000000000) + ' B'
      case rawPrice > 1000000:
        return GrPrice.convertDollarToDollar(rawPrice / 1000000) + ' M'
      case rawPrice > 1000:
        return GrPrice.convertDollarToDollar(rawPrice / 1000) + ' K'
      default:
        return GrPrice.convertDollarToDollar(rawPrice)
    }
  }

  static renderDollarString(rawPrice: number | null) {
    const dollarValue = rawPrice ? rawPrice / 100 : 0

    return GrPrice.convertNumberPriceToStringPrice(dollarValue)
  }

  static convertDecimalString(
    amount: number,
    minimumFractionDigits = 2,
    maximumFractionDigits = 2,
  ) {
    return amount.toLocaleString(undefined, { minimumFractionDigits, maximumFractionDigits })
  }

  static profitMarginPercentage(cost: number, revenue: number) {
    const profit = revenue - cost
    const margin = (profit / revenue) * 100
    return margin && isFinite(margin) ? `${margin.toFixed(2)}%` : '-%'
  }
}

export class GrRegex {
  // Auto escapes special characters
  static regEscape = (str: string) => {
    // eslint-disable-next-line no-useless-escape
    return str.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')
  }

  static replaceLatin1Space = (str: string) => {
    return str.replace(/[\xa0]/g, ' ')
  }

  static isValidCRSA = (str: string) => {
    const crsaRegex = /^CRSA[0-9]{7}$/
    return crsaRegex.test(str)
  }

  static replaceDoubleQuotes = (str: string) => {
    return str.replace(/\\?"/g, "'")
  }

  // eslint-disable-next-line no-control-regex
  static unicodeRegex = new RegExp(/[^\x00-\x7F]+/g)

  static notNumberRegex = new RegExp(/[^\d]+\b/)

  static cleanNotNumbers = (str: string) => {
    return str.replace(/\D/g, '')
  }

  // Replace some frequently appearing special characters into acceptable counter parts
  static cleanFrequentSpecialCharacters = (str: string, isJsonOutput?: boolean) => {
    let cleanString = str
    if (isJsonOutput) {
      cleanString = cleanString.replace(/“/g, `\\"`).replace(/”/g, `\\"`)
    } else {
      cleanString = cleanString.replace(/“/g, `"`).replace(/”/g, `"`)
    }
    return cleanString
      .replace(/¼/g, '1/4')
      .replace(/’/g, `'`)
      .replace(/‘/g, `'`)
      .replace(/`/g, `'`)
      .replace(/•/g, '-')
      .replace(/–/g, '-')
      .replace(/—/g, '-')
      .replace(/[\u202F\u00A0]/g, ' ')
      .replace(GrRegex.unicodeRegex, '')
  }

  static dateRegex = new RegExp(/[^0-9/]/g)

  static postalCodeCA = new RegExp(/^([A-Z]([0-9]([A-Z]([0-9]([A-Z]([0-9])?)?)?)?)?)$/)
  static specialCharacters = new RegExp(/[+?^$[\]{}()\\./<>|;]/g)
  static excludeFlatRate = new RegExp(/^(?!.*\bflat\s*rate\b).*$/i)
}

export class GrTime {
  private static createTimeStr = ({
    duration,
    singluarUnit,
  }: {
    duration: number
    singluarUnit: string
  }): string => {
    let str: string

    if (duration === 0) {
      str = ``
    } else if (0 < duration && duration <= 1) {
      str = `${duration} ${singluarUnit}`
    } else {
      str = `${duration} ${singluarUnit}s`
    }

    return str
  }

  static convertMinsToHrsMins = (minutes: number): string => {
    if (minutes === 0) {
      return '0 hr'
    }

    const h = Math.floor(minutes / 60)
    const m = minutes % 60

    const hour = GrTime.createTimeStr({ duration: h, singluarUnit: 'hr' })
    const min = GrTime.createTimeStr({ duration: m, singluarUnit: 'min' })

    return min ? `${hour} ${min}` : hour
  }
}

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const filterOption = (input: string, option: React.ReactElement<OptionProps>) => {
  const optionName = option.props.children

  if (optionName && typeof optionName === 'string') {
    const lowerCaseInput = input.toLowerCase().trim()

    return optionName.trim().toLowerCase().indexOf(lowerCaseInput) >= 0
  }

  return true
}

export const filterOptionV4: SelectProps<object>['filterOption'] = (inputValue, optionData) => {
  if (!optionData) return true

  // `optionData.children` only works when use children as option data
  const optionName = optionData.label ? optionData.label : optionData.children

  if (optionName && typeof optionName === 'string') {
    const lowerCaseInput = inputValue.toLowerCase().trim()

    return optionName.trim().toLowerCase().indexOf(lowerCaseInput) >= 0
  }

  return true
}

export function parseAndDownloadCSV(filename: string, json: any, fields?: string[]) {
  let csvString: string
  if (fields) {
    csvString = Papa.unparse({
      fields: fields,
      data: json,
    })
  } else {
    csvString = Papa.unparse(json)
  }
  // Reference: https://github.com/mholt/PapaParse/issues/175
  const blob = new Blob([csvString])
  if (window.navigator.hasOwnProperty('msSaveOrOpenBlob')) {
    window.navigator.msSaveBlob(blob, `${filename}.csv`)
  } else {
    const a = window.document.createElement('a')
    a.href = (window.URL as any).createObjectURL(blob, { type: 'text/plain' } as any)
    a.download = `${filename}.csv`
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }
}

export function printHTML(id: string, title?: string) {
  // Add iFrame (where printing HTML is set)
  const iframeId = `print-frame-${new Date().getTime()}`
  const iframe = document.createElement('iframe')
  iframe.id = iframeId
  iframe.name = iframeId
  iframe.src = 'about:blank'
  document.body.appendChild(iframe)

  // Set
  const a = (window.document.getElementById('printing-css') as HTMLTextAreaElement).value
  const b = window.document.getElementById(id)!.innerHTML
  const printFrame = (window as any).frames[iframeId]
  printFrame.document.title = title
  printFrame.document.body.innerHTML = '<style>' + a + '</style>' + b
  printFrame.window.focus()
  printFrame.window.print()

  // Remove iframe
  document.getElementById(iframeId)!.outerHTML = ''
}

export const parseErrorMsg = (error: any): string | undefined =>
  error?.response?.data?.message || error?.message

export const jsonToQueryString = (json: any): string => {
  return Object.keys(json)
    .filter((key) => json[key] !== undefined)
    .map((key) => {
      if (isArray(json[key])) {
        return encodeURIComponent(key) + '=' + JSON.stringify(json[key])
      } else {
        return encodeURIComponent(key) + '=' + encodeURIComponent(json[key])
      }
    })
    .join('&')
}

export const transactionsIncomplete = (payment: IPayment) => {
  const pendingTransactions = payment.transactions.filter(
    (trans) =>
      trans.status === TransactionStatus.PENDING || trans.status === TransactionStatus.PROCESSING,
  )
  return pendingTransactions.length > 0
}

export function makeRandomAlphaNumericString(): string {
  return Math.random().toString(36).slice(2)
}

export function makeDefaultTooltipId(label: string): string {
  const kebabCase = label.toLowerCase().replace(/ /g, (str) => '-')
  return `${kebabCase}-${makeRandomAlphaNumericString()}`
}

/**
 * Add leading zeros to javascript numbers
 *
 * @param {*} num
 * @param {*} padlen
 * @param {*} padchar
 * @returns
 */
export function numberPadder(num: number, padlen: number, padchar?: string): string {
  const pad_char = typeof padchar !== 'undefined' ? padchar : '0'
  const pad = new Array(1 + padlen).join(pad_char)
  return (pad + num).slice(-pad.length)
}

export function getCategoryName(category: IBaseCategory) {
  if (category.parentCategoryId) {
    return `> ${category.name}`
  }
  return category.name
}

export const swapArrayElements = (array: any[], index1: number, index2: number) => {
  const updatedArray = [...array]
  const tempElement = updatedArray[index1]
  updatedArray[index1] = updatedArray[index2]
  updatedArray[index2] = tempElement
  return updatedArray
}

export const getUniqueArray = (
  array: Array<string | number | null>,
): Array<string | number | null> => [...new Set(array)]

export const getArrayDuplicates = (
  array: Array<string | number | null>,
): Array<string | number | null> => {
  const duplicates = array.filter((value, index, self) => self.indexOf(value) !== index)
  return [...new Set(duplicates)]
}

export const getUniqueArrayOfObjects = (arrayOfObjects: any[], uniqueKey: string): any[] => {
  const uniqueResults = []
  const map = new Map()

  for (const object of arrayOfObjects) {
    if (!map.has(object[uniqueKey])) {
      map.set(object[uniqueKey], true)
      uniqueResults.push({ ...object })
    }
  }

  return uniqueResults
}

export const uploadFileToS3Bucket = (
  file: any,
  signedUrl: string,
  contentType: string,
): XMLHttpRequest => {
  const xhr = new XMLHttpRequest()

  xhr.open('PUT', signedUrl)
  xhr.setRequestHeader('Content-Type', contentType)
  xhr.setRequestHeader('x-amz-acl', 'public-read')
  xhr.send(file)

  return xhr
}

export const uploadImageToCloudinary = (
  file: any,
  uploadUrlDetails: CloudinaryDetailsResponse,
): { xhr: XMLHttpRequest; formData: FormData } => {
  const { apiKey, timestamp, url, signature } = uploadUrlDetails

  const xhr = new XMLHttpRequest()

  xhr.open('POST', url)

  const formData = new FormData()
  formData.set('file', file)
  formData.set('signature', signature)
  formData.set('timestamp', timestamp)
  formData.set('api_key', apiKey)

  return { xhr, formData }
}

export const uploadImageAndGetCloudinaryUrl = async (
  file: any,
  currentCompanyStore: CurrentCompanyStore,
): Promise<string> => {
  if (!file.includes(CLOUDINARY)) {
    const uploadUrlDetails: CloudinaryDetailsResponse = currentCompanyStore?.company?.id
      ? await currentCompanyStore!.getSignedImageUploadPublicUrl()
      : await currentCompanyStore!.getSignedAdminImageUploadPublicUrl()

    const { xhr, formData } = await uploadImageToCloudinary(file, uploadUrlDetails)
    return new Promise((resolve, reject) => {
      xhr.onload = () => {
        if (xhr.status === 200) {
          const responseData: CloudinaryResponse = JSON.parse(xhr.responseText)
          resolve(responseData.secure_url)
        } else {
          reject(new Error(xhr.statusText))
        }
      }
      xhr.send(formData)
    })
  } else {
    return file
  }
}

export const prepopulateEmail = (emailDetail: IEmailDetail): string => {
  let result = ''
  const keys = Object.keys(emailDetail)

  keys.forEach((key, index) => {
    const separator = key === 'mailto' ? ':' : '='

    const emailString = `${key}${separator}${encodeURIComponent(emailDetail[key])}`

    let joiner = key === 'mailto' ? '?' : '&'
    if (index === keys.length - 1) {
      joiner = ''
    }

    result += `${emailString}${joiner}`
  })

  return result
}

export const parseProvinceProductAgency = (province: Province | null) => {
  switch (province) {
    case 'AB':
      return 'AGLC'
    case 'ON':
      return 'OCS'
    case 'BC':
      return 'BCLDB'
    default:
      return `${BLAZE_COMPANY} catalogue`
  }
}

export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

export const orderedExportData = (fields: string[], data?: any, taxHeaders?: string[]) => {
  if (!data) {
    return []
  }
  const ordered = data.map((row: any) => {
    const taxes = {}
    if (taxHeaders) {
      const groupedTaxes = groupBy(row[OrderedExportDataFields.TAXES], 'Tax')
      taxHeaders.forEach((header: string) => {
        if (groupedTaxes[header]) {
          taxes[header] = parseFloat(
            groupedTaxes[header][0][OrderedExportDataFields.AMOUNT].toFixed(2),
          )
        } else {
          taxes[header] = 0
        }
      })
    }

    let orderedObject = {}
    fields.forEach((field) => {
      if (field !== OrderedExportDataFields.TAXES) {
        if (typeof row[field] === 'number') {
          orderedObject[field] = parseFloat((row[field] as number).toFixed(2))
        } else if (field === OrderedExportDataFields.DATE) {
          orderedObject[field] = row[field].substring(0, 10)
        } else {
          orderedObject[field] = row[field]
        }
      } else {
        orderedObject = { ...orderedObject, ...taxes }
      }
    })

    return orderedObject
  })

  return ordered
}

export const abbreviateText = (text: string, maxNum = 20) => {
  if (text.length < maxNum) return { isAbbr: false, text }

  const abbrText = text.substring(0, maxNum) + '...'

  return { isAbbr: true, text: abbrText }
}

export const createInvoiceHTML = (fontSize: string, parkedSaleStore: ParkedSaleStore) => {
  const { company, parkedSaleModalQueue, productPairQueue, currentLocation, currentDevice } =
    parkedSaleStore || {}
  const paymentQueue = parkedSaleModalQueue!
  const companyName = company!.name
  const { receiptSettings } = currentLocation!
  const receiptLocationNameText = receiptSettings.receiptLocationNameText
  const receiptLocationDescriptionText = receiptSettings.receiptLocationDescriptionText
  const receiptAddressText = receiptSettings.receiptAddressText
  const completeDate =
    paymentQueue.status === PaymentQueueStatus.COMPLETED && paymentQueue.completedPayment
      ? paymentQueue.completedPayment.completeDate
      : null

  const payment = {
    customId: `${paymentQueue.externalSourceName || BLAZE_COMPANY} - ${
      paymentQueue.externalOrderId || 'No order ID'
    }`,
    createDate: paymentQueue.createDate,
    completeDate,
    deviceName: currentDevice?.deviceId || '',
    customerName: paymentQueue.customerName,
    productPairQueue,
  }
  const receiptShowSku = receiptSettings.receiptShowSku

  return `
  <div id='print-section' style='padding-left: 10px; padding-right: 10px;'>
    <table 
      style='width: 100%;
      border: none;
      border-width: 0;
      border-collapse: collapse;
      padding: 0;
      ${fontSize ? 'font-size: ' + fontSize + ';' : ''}'
    >
      <tbody>
        <tr>
          <td colspan=2;>
            <b 
              style='font-size: 1.5em; 
              font-weight: 600;'
            >${companyName}</b>
          </td>
        </tr>

        ${
          receiptLocationNameText
            ? `
              <tr>
                <td colspan=2>${receiptLocationNameText}</td>
              </tr>
              `
            : ''
        }

        ${
          receiptLocationDescriptionText
            ? `
              <tr>
                <td 
                  colspan=2;
                  style='margin-bottom: 20px;
                  white-space: pre-line;
                  overflow-wrap: break-word;
                  word-break: break-all'
                >${receiptLocationDescriptionText}</td>
              </tr>
              `
            : ''
        }

        ${
          receiptAddressText
            ? `
              <tr>
                <td colspan=2>${receiptAddressText}</td>
              </tr>
              `
            : ''
        }
      </tbody>
    </table>

    <table 
      style='margin-top: 1.5rem;
      width: 100%;
      border: none;
      border-width: 0;
      border-collapse: collapse;
      padding: 0;
      ${fontSize ? 'font-size: ' + fontSize + ';' : ''}'
    >
      <tbody>
        ${
          payment.customId
            ? `<tr>
                  <td style='border-top: solid; border-width: 1px; '>Order ID</td>
                  <td style='border-top: solid; border-width: 1px;  text-align: right'>${payment.customId}</td>
                </tr>`
            : ''
        }

        ${
          payment.completeDate
            ? `
                <tr>
                  <td style='border-top: solid; border-width: 1px;'>Completed</td>
                  <td style='border-top: solid; border-width: 1px; text-align: right'>
                    ${moment(payment.completeDate).format('lll')}
                  </td>
                </tr>
              `
            : `
                <tr>
                  <td style='border-top: solid; border-width: 1px; '>Created</td>
                  <td style='border-top: solid; border-width: 1px;  text-align: right'>
                    ${moment(payment.createDate).format('lll')}
                  </td>
                </tr>
              `
        }

        <tr>
          <td style='border-top: solid; border-width: 1px;'>POS</td>
          <td style='border-top: solid; border-width: 1px; text-align: right'>
            ${payment.deviceName || '-'}
          </td>
        </tr>

        ${
          payment.customerName
            ? `
                  <tr>
                    <td style='border-top: solid; border-width: 1px; '>Customer</td>
                    <td style='border-top: solid; border-width: 1px;  text-align: right'>${payment.customerName}</td>
                  </tr>
                `
            : ''
        }
      </tbody>
    </table>

    <table 
      style='margin-top: 1.5rem;
      width: 100%;
      border: none;
      border-width: 0;
      border-collapse: collapse;
      padding: 0;
      ${fontSize ? 'font-size: ' + fontSize + ';' : ''}'
    >
      <tbody>
        <tr>
          <td><b>Product</b></td>
          <td style='text-align: right'><b>Qty</b></td>
          <td style='text-align: right'><b>Price</b></td>
        </tr>

        ${paymentQueue.paymentLines
          .map((queue) => {
            const { productId, productName } = queue

            if (!productId && productName) {
              return `
                  <tr>
                    <td style='border-top: solid; border-width: 1px;'>
                      <div class='d-flex' style='flex-direction: column;'>
                        <div>${queue.productName}</div>
                        ${``}
                        ${
                          queue.discountMessage
                            ? `<div style='font-size: .9em'>${queue.discountMessage}</div>`
                            : ``
                        }
                      </div>
                    </td>
                    <td 
                      style='border-top: solid;
                      border-width: 1px;
                      padding-right:.5rem;
                      text-align: right'
                    >${queue.quantity}</td>
                    <td 
                      style='border-top: solid;
                      border-width: 1px;
                      text-align: right'
                    >
                      ${
                        queue.discountPrice
                          ? GrPrice.convertCentToDollar(queue.discountPrice * queue.quantity)
                          : '$0.00'
                      }
                    </td>
                  </tr>
                `
            }
            if (!productId) return

            const targetPair = productPairQueue.get(productId)

            if (!targetPair) {
              return
            }

            const { product, paymentLine } = targetPair

            const unitString = paymentLine.quantity.toString()
            const price =
              paymentLine.price && paymentLine.quantity > 0
                ? paymentLine.price / paymentLine.quantity
                : product.price || 0

            if (paymentLine.discountPrice < 0 || paymentLine.discountPercentage > 0) {
              return `
                  <tr>
                    <td style='border-top: solid; border-width: 1px;'>
                      <div class='d-flex' style='flex-direction: column;'>
                        <div>${paymentLine.productName}</div>
                        ${
                          receiptShowSku && paymentLine.sku
                            ? `<div style='font-size:.8em'>SKU: ${paymentLine.sku}</div>`
                            : ``
                        }

                        ${
                          paymentLine.discountMessage
                            ? `<div style='font-size: .9em'>${paymentLine.discountMessage}</div>`
                            : ``
                        }
                      </div>
                    </td>

                    <td 
                      style='border-top: solid;
                      border-width: 1px;
                      padding-right:.5rem;
                      text-align: right'
                    >${unitString}</td>

                    <td 
                      style='border-top: solid;
                      border-width: 1px;
                      text-align: right'
                    >
                      ${
                        product
                          ? GrPrice.convertCentToDollar(price * paymentLine.quantity)
                          : '$0.00'
                      }
                    </td>
                  </tr>

                  ${
                    paymentLine.discountPrice &&
                    `
                      <tr>
                        <td>${paymentLine.discountMessage}</td>
                        <td></td>
                        <td style='text-align: right;'>${GrPrice.convertCentToDollar(
                          paymentLine.discountPrice,
                        )}</td>
                      </tr>
                    `
                  }
                `
            } else {
              return `
                  <tr>
                    <td style='border-top: solid; border-width: 1px; '>
                      <div class='d-flex' style='flex-direction: column;'>
                        <div>${paymentLine.productName}</div>
                        ${
                          receiptShowSku && paymentLine.sku
                            ? `<div style='font-size:.8em'>SKU: ${paymentLine.sku}</div>`
                            : ``
                        }
                        ${
                          paymentLine.discountMessage
                            ? `<div style='font-size: .9em'>${paymentLine.discountMessage}</div>`
                            : ``
                        }
                      </div>
                    </td>

                    <td 
                      style='border-top: solid;
                      border-width: 1px;
                      padding-right:.5rem;
                      text-align: right'
                    >${unitString}</td>

                    <td 
                      style='border-top: solid;
                      border-width: 1px;
                      text-align: right'
                      >
                      ${
                        product
                          ? GrPrice.convertCentToDollar(price * paymentLine.quantity)
                          : '$0.00'
                      }
                    </td>
                  </tr>

                  ${
                    paymentLine.discountPrice
                      ? `
                      <tr>
                        <td>${paymentLine.discountMessage}</td>
                        <td></td>
                        <td style='text-align: right;'> ${GrPrice.convertCentToDollar(
                          paymentLine.discountPrice,
                        )}</td>
                      </tr>
                    `
                      : ''
                  }
                  `
            }
          })
          .join('')}
      </tbody>
    </table>

    <table 
      style='margin-top: 1.5rem;
      width: 100%;
      border: none;
      border-width: 0;
      border-collapse: collapse;
      padding: 0;
      ${fontSize ? 'font-size: ' + fontSize + ';' : ''}'
    >
      <tbody>
        ${
          paymentQueue.notes
            ? `
                <tr>
                  <td><b>Notes</b></td>
                </tr>
                <tr>
                  <td 
                    colspan=2;
                    style='border-top: solid;
                    border-width: 1px;
                    margin-bottom: 20px;
                    white-space: pre-line;
                    overflow-wrap: break-word;
                    word-break: break-all'
                  >${paymentQueue.notes}</td>
                </tr>
                `
            : ''
        }
      </tbody>
    </table>
  </div>
  `
}

export const processPackingSlipData = <T extends ParkedSaleStore | TPOSData>(
  company: ICompany,
  param: TPackingSlipData<T>,
  customerPhone?: string | null,
) => {
  if (param instanceof ParkedSaleStore) {
    const data: PackingSlipData = {
      company: company,
      location: param.currentLocation,
      device: param.currentDevice,
      paymentQueue: param.parkedSaleModalQueue || undefined,
      customerName: isNull(param.parkedSaleModalQueue?.customerName)
        ? undefined
        : param.parkedSaleModalQueue?.customerName,
      customerPhone: customerPhone || null,
      paymentData: param.parkedSaleModalQueue || undefined,
      orderStatus: param.parkedSaleModalQueue?.orderType,
    }

    return data
  } else {
    const source = param as TPOSData
    const payment = source.posStore?.currentPayment
    const data: PackingSlipData = {
      company: company,
      location: source.posStore?.location,
      device: source.posStore?.device,
      paymentQueue: source.paymentQueue,
      customerName:
        payment?.customerWithHistory?.customer.name ||
        source.paymentQueue?.customerName ||
        undefined,
      customerPhone: payment?.customerWithHistory?.customer.phone || null,
      paymentData: source.payment,
      orderStatus: source.paymentQueue?.orderType,
    }

    return data
  }
}

export const createPackingSlipHTML = (
  fontSize: string,
  packingSlipData: PackingSlipData,
  parkedSaleStore?: ParkedSaleStore,
) => {
  const { company, location, device, paymentQueue, customerName, customerPhone, paymentData } =
    packingSlipData

  if (!location || !paymentQueue || !paymentData) return ''

  const companyName = company?.name || 'no company name'
  const { receiptSettings } = location
  const receiptLocationNameText = receiptSettings.receiptLocationNameText
  const receiptLocationDescriptionText = receiptSettings.receiptLocationDescriptionText
  const receiptAddressText = receiptSettings.receiptAddressText
  const completeDate =
    paymentQueue.status === PaymentQueueStatus.COMPLETED && paymentQueue.completedPayment
      ? paymentQueue.completedPayment.completeDate
      : null
  const isPaid = paymentQueue.completedPayment ? 'Paid' : 'Unpaid'
  const payment = {
    customId: `${paymentQueue.externalSourceName || BLAZE_COMPANY} - ${
      paymentQueue.externalOrderId || 'No order ID'
    }`,
    createDate: paymentQueue.createDate,
    completeDate,
    deviceName: device?.deviceId || '',
  }
  const receiptShowSku = receiptSettings.receiptShowSku
  const paymentLines: (CreatePaymentLine | CreateGiftCardPaymentLine | IPaymentQueueLine)[] =
    paymentData.paymentLines
  const notes =
    paymentData instanceof CreatePayment ? paymentData.paymentQueueNotes : paymentData.notes

  return `
  <div id='print-section' style='padding-left: 10px; padding-right: 10px;'>
    <table 
      style='width: 100%;
      border: none;
      border-width: 0;
      border-collapse: collapse;
      padding: 0;
      ${fontSize ? 'font-size: ' + fontSize + ';' : ''}'
    >
      <tbody>
        <tr>
          <td colspan=2;>
            <b 
              style='font-size: 1.5em; 
              font-weight: 600;'
            >${companyName}</b>
          </td>
          <td style='text-align: right;'>
            <b>${isPaid}</b>
          </td>
        </tr>

        ${
          receiptLocationNameText
            ? `
              <tr>
                <td colspan=2>${receiptLocationNameText}</td>
              </tr>
              `
            : ''
        }

        ${
          receiptLocationDescriptionText
            ? `
              <tr>
                <td 
                  colspan=2;
                  style='margin-bottom: 20px;
                  white-space: pre-line;
                  overflow-wrap: break-word;
                  word-break: break-all'
                >${receiptLocationDescriptionText}</td>
              </tr>
              `
            : ''
        }

        ${
          receiptAddressText
            ? `
              <tr>
                <td colspan=2>${receiptAddressText}</td>
              </tr>
              `
            : ''
        }
      </tbody>
    </table>

    <table 
      style='margin-top: 1.5rem;
      width: 100%;
      border: none;
      border-width: 0;
      border-collapse: collapse;
      padding: 0;
      ${fontSize ? 'font-size: ' + fontSize + ';' : ''}'
    >
      <tbody>
        ${
          payment.customId
            ? `<tr>
                  <td style='border-top: solid; border-width: 1px; '>Order ID</td>
                  <td style='border-top: solid; border-width: 1px;  text-align: right'>${payment.customId}</td>
                </tr>`
            : ''
        }

        ${
          payment.completeDate
            ? `
                <tr>
                  <td style='border-top: solid; border-width: 1px;'>Completed</td>
                  <td style='border-top: solid; border-width: 1px; text-align: right'>
                    ${moment(payment.completeDate).format('lll')}
                  </td>
                </tr>
              `
            : `
                <tr>
                  <td style='border-top: solid; border-width: 1px; '>Created</td>
                  <td style='border-top: solid; border-width: 1px;  text-align: right'>
                    ${moment(payment.createDate).format('lll')}
                  </td>
                </tr>
              `
        }

        <tr>
          <td style='border-top: solid; border-width: 1px;'>POS</td>
          <td style='border-top: solid; border-width: 1px; text-align: right'>
            ${payment.deviceName || '-'}
          </td>
        </tr>

        ${
          customerName
            ? `
                  <tr>
                    <td style='border-top: solid; border-width: 1px; '>Customer</td>
                    <td style='border-top: solid; border-width: 1px;  text-align: right'>${customerName}</td>
                  </tr>
                `
            : ''
        }

        ${
          customerPhone
            ? `
                  <tr>
                    <td style='border-top: solid; border-width: 1px; '>Phone #</td>
                    <td style='border-top: solid; border-width: 1px;  text-align: right'>${customerPhone}</td>
                  </tr>
                `
            : ''
        }
      </tbody>
    </table>

    <table 
      style='margin-top: 1.5rem;
      width: 100%;
      border: none;
      border-width: 0;
      border-collapse: collapse;
      padding: 0;
      ${fontSize ? 'font-size: ' + fontSize + ';' : ''}'
    >
      <tbody>
        <tr>
          <td><b>Product</b></td>
          <td style='text-align: right'><b>Qty</b></td>
          <td style='text-align: right'><b>Price</b></td>
        </tr>
        ${paymentLines
          .map((paymentLine: CreatePaymentLine | CreateGiftCardPaymentLine | IPaymentQueueLine) => {
            const { productId, productName } = paymentLine
            const quantity =
              paymentLine instanceof CreatePaymentLine ||
              paymentLine instanceof CreateGiftCardPaymentLine
                ? paymentLine.units
                : paymentLine.quantity

            if (!productId && productName) {
              return `
                  <tr>
                    <td style='border-top: solid; border-width: 1px;'>
                      <div class='d-flex' style='flex-direction: column;'>
                        <div>${paymentLine.productName}</div>
                        ${``}
                        ${
                          paymentLine.discountMessage
                            ? `<div style='font-size: .9em'>${paymentLine.discountMessage}</div>`
                            : ``
                        }
                      </div>
                    </td>
                    <td 
                      style='border-top: solid;
                      border-width: 1px;
                      padding-right:.5rem;
                      text-align: right'
                    >${quantity}</td>
                    <td 
                      style='border-top: solid;
                      border-width: 1px;
                      text-align: right'
                    >
                      ${
                        paymentLine.discountPrice
                          ? GrPrice.convertCentToDollar(paymentLine.discountPrice * quantity)
                          : '$0.00'
                      }
                    </td>
                  </tr>
                `
            }
            if (!productId) return

            let price = 0
            if (parkedSaleStore) {
              const targetPair = parkedSaleStore.productPairQueue.get(productId)
              if (!targetPair) {
                return
              }
              const { product, paymentLine } = targetPair
              price =
                paymentLine.price && paymentLine.quantity > 0
                  ? paymentLine.price / paymentLine.quantity
                  : product.price || 0
            } else if (
              paymentLine instanceof CreatePaymentLine ||
              paymentLine instanceof CreateGiftCardPaymentLine
            ) {
              price = paymentLine.pricePerUnit || 0
            } else price = paymentLine.price! / quantity || 0

            const unitString = quantity.toString()

            if (paymentLine.discountPrice < 0 || paymentLine.discountPercentage > 0) {
              return `
                  <tr>
                    <td style='border-top: solid; border-width: 1px;'>
                      <div class='d-flex' style='flex-direction: column;'>
                        <div>${paymentLine.productName}</div>
                        ${
                          receiptShowSku && paymentLine.sku
                            ? `<div style='font-size:.8em'>SKU: ${paymentLine.sku}</div>`
                            : ``
                        }

                        ${
                          paymentLine.discountMessage
                            ? `<div style='font-size: .9em'>${paymentLine.discountMessage}</div>`
                            : ``
                        }
                      </div>
                    </td>

                    <td 
                      style='border-top: solid;
                      border-width: 1px;
                      padding-right:.5rem;
                      text-align: right'
                    >${unitString}</td>

                    <td 
                      style='border-top: solid;
                      border-width: 1px;
                      text-align: right'
                    >
                      ${paymentLine ? GrPrice.convertCentToDollar(price * quantity) : '$0.00'}
                    </td>
                  </tr>

                  ${
                    paymentLine.discountPrice &&
                    `
                      <tr>
                        <td>${paymentLine.discountMessage}</td>
                        <td></td>
                        <td style='text-align: right;'>${GrPrice.convertCentToDollar(
                          paymentLine.discountPrice,
                        )}</td>
                      </tr>
                    `
                  }
                `
            } else {
              return `
                  <tr>
                    <td style='border-top: solid; border-width: 1px; '>
                      <div class='d-flex' style='flex-direction: column;'>
                        <div>${paymentLine.productName}</div>
                        ${
                          receiptShowSku && paymentLine.sku
                            ? `<div style='font-size:.8em'>SKU: ${paymentLine.sku}</div>`
                            : ``
                        }
                        ${
                          paymentLine.discountMessage
                            ? `<div style='font-size: .9em'>${paymentLine.discountMessage}</div>`
                            : ``
                        }
                      </div>
                    </td>

                    <td 
                      style='border-top: solid;
                      border-width: 1px;
                      padding-right:.5rem;
                      text-align: right'
                    >${unitString}</td>

                    <td 
                      style='border-top: solid;
                      border-width: 1px;
                      text-align: right'
                      >
                      ${paymentLine ? GrPrice.convertCentToDollar(price * quantity) : '$0.00'}
                    </td>
                  </tr>

                  ${
                    paymentLine.discountPrice
                      ? `
                      <tr>
                        <td>${paymentLine.discountMessage}</td>
                        <td></td>
                        <td style='text-align: right;'> ${GrPrice.convertCentToDollar(
                          paymentLine.discountPrice,
                        )}</td>
                      </tr>
                    `
                      : ''
                  }
                  `
            }
          })
          .join('')}
      </tbody>
    </table>

    <table 
      style='margin-top: 1.5rem;
      width: 100%;
      border: none;
      border-width: 0;
      border-collapse: collapse;
      padding: 0;
      ${fontSize ? 'font-size: ' + fontSize + ';' : ''}'
    >
      <tbody>
        ${
          notes
            ? `
                <tr>
                  <td><b>Notes</b></td>
                </tr>
                <tr>
                  <td 
                    colspan=2;
                    style='border-top: solid;
                    border-width: 1px;
                    margin-bottom: 20px;
                    white-space: pre-line;
                    overflow-wrap: break-word;
                    word-break: break-all'
                  >${notes}</td>
                </tr>
                `
            : ''
        }
      </tbody>
    </table>
  </div>
  `
}

export const splitHighlightWords = (word: string) => {
  return [...word.split(' ')]
}

export const isGiftCardTransaction = (transaction: ITransaction) =>
  GiftCardPaymentTypeIds.includes(transaction.paymentTypeId)

export const isWorldpayTransaction = (
  transaction: CreateTransaction | CreateGiftCardTransaction | ITransaction,
) => transaction.paymentTypeId === PaymentTypeIds.worldpay

export const removeDecimalPoint = (value: number | string) => {
  return Number(`${value}`.replace('.', ''))
}

export const getPopupContainer = () => {
  // https://ant.design/components/select/#Select-props
  return (document.getElementById('app-main-view') as HTMLElement) || document.body
}

export function csvStringToFile(csvString: string, filename: string) {
  const blob = new Blob([csvString])

  const a = window.document.createElement('a')
  a.href = (window.URL as any).createObjectURL(blob, { type: 'text/plain' } as any)
  a.download = `${filename}.csv`
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

export const isJsonString = (str: string) => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export const checkTypeWithProperty = <objectType extends {}, propKey extends PropertyKey>(
  obj: objectType,
  prop: propKey,
): obj is objectType & Record<propKey, unknown> => {
  return Object.prototype.hasOwnProperty.call(obj, prop)
}

export const isPhoneNumberValid = (phone: string) => {
  const phoneRegex = /^\+?[0-9]{10,11}$/

  // Validate if there is phone number
  return phone ? phoneRegex.test(phone) : true
}

export const isASCII = (str: string, extended = false) => {
  return (extended ? /^[\x20-\xFF]*$/ : /^[\x20-\x7F]*$/).test(str)
}

export const isEmailValid = (email: string) => {
  // Use Validate.js to validate email address. Returns true if no email address passed
  return email ? isEmail(email) && isASCII(email) : true
}

export const getFullNotes = (isNextLine: boolean, notes?: string, paymentQueueNotes?: string) => {
  const space = isNextLine ? `\n` : ` `
  if (notes) {
    if (notes !== paymentQueueNotes) {
      // To handle old notes which will have same notes in paymentQueue and payment
      if (paymentQueueNotes) {
        return notes + space + paymentQueueNotes // To combine sales notes and paymentQueue notes
      } else {
        return notes
      }
    } else {
      return notes
    }
  } else {
    if (paymentQueueNotes) {
      return paymentQueueNotes
    } else {
      return ''
    }
  }
}

export const replaceArrayValue = <T extends unknown>(array: T[], search: T, replace: T) => {
  const newArr = [...array]
  const index = newArr.indexOf(search)
  if (index !== -1) {
    newArr[index] = replace
  }

  return newArr
}

export const bulkRenameObjectProperty = <T extends unknown>(
  array: T[] | undefined,
  oldProperty: keyof T,
  newProperty: keyof T,
) => {
  return array?.map((object) => {
    if (object[oldProperty]) {
      object[newProperty] = object[oldProperty]
      delete object[oldProperty]
    }
    return object
  })
}

export const sanitizeHtmlTag = (element: string) => {
  const htmlTagSanitized = element.replace(/(<([^>]+)>)/gi, '')
  return htmlTagSanitized
}

export const isSystemProduct = (product?: { productType: ProductModels.ProductType }) => {
  return (
    product?.productType === ProductModels.ProductType.FEE ||
    product?.productType === ProductModels.ProductType.TIP
  )
}

export const checkForQuantity = (nonBundleProductLines: CreatePayment['nonBundleProductLines']) => {
  const initial = 0
  return (
    nonBundleProductLines.reduce(
      (accumulator: number, currentValue: CreatePaymentLine) => accumulator + currentValue.units,
      initial,
    ) === 0
  )
}

export function hasCashAmountErr(payment: CreatePayment) {
  const cashPayCheck = payment.transactions.some(
    (trans) =>
      Math.abs(trans.amount) < 5 &&
      Math.abs(trans.amount) > 0 &&
      trans.paymentTypeId === PaymentTypeIds.cash,
  )

  return (
    (payment.tendered > Math.abs(payment.totalAmount) && payment.refundPaymentId) || !!cashPayCheck
  )
}

interface IAccumulator {
  addition: number
  reduction: number
}

export const getTotalUnitDiscrepancies = (flattenedLogs: IInventoryLog[]) => {
  const { addition, reduction } = flattenedLogs.reduce(
    (prev: IAccumulator, log: IInventoryLog) => {
      if (log.updateValue >= 0) {
        prev.addition = prev.addition + log.updateValue
      } else {
        prev.reduction = prev.reduction + log.updateValue
      }
      return prev
    },
    { addition: 0, reduction: 0 },
  )
  const totalAddition = addition
  const totalReduction = reduction
  const totalLog = totalAddition - Math.abs(totalReduction)
  return {
    sumOfAdditions: totalAddition,
    sumOfReductions: totalReduction,
    total: totalLog,
  }
}

export const sortLogsByTotalDiscrepancy = (current: IInventoryLog[], next: IInventoryLog[]) => {
  const { total: totalCurrent } = getTotalUnitDiscrepancies(current)
  const { total: totalNext } = getTotalUnitDiscrepancies(next)
  return Math.abs(totalNext) - Math.abs(totalCurrent)
}

export const getLocationsWithCRSA = (locations: ILocationContract[] | undefined) => {
  const filterValidCRSALocation = (location: ILocationContract) =>
    GrRegex.isValidCRSA(location.storeId || '')

  const locationsWithCRSA: ILocationContract[] = locations
    ? locations.filter(filterValidCRSALocation)
    : []

  return locationsWithCRSA
}
