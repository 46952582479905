import { observable, observe, action, computed } from 'mobx'
import {
  Unit,
  IChildProduct,
  ICreateMultiLocationPrice,
  IProductMetaDataItem,
  INestedProduct,
} from '@getgreenline/homi-shared'
import { uploadImageAndGetCloudinaryUrl } from '../../../../utilities/helpers'
import { CurrentCompanyStore } from '../../../../stores/CurrentCompanyStore'
import { CLOUDINARY } from '../../../../constants/general'
import { LotModels, ProductModels } from '@getgreenline/products'
import {
  IBaseProduct,
  IProductMetaData,
  ProductType,
  EProductStrainType,
} from '@getgreenline/products/build/models/product'
import { ILotContract } from '@getgreenline/products/build/models/lot'
import { CBD_THC_UNIT } from './DetailsSection/Cannabinoids'
import { TagModels } from '@getgreenline/tags'

type TOmitBaseProduct =
  | 'id'
  | 'categoryColorHex'
  | 'categoryName'
  | 'childProducts'
  | 'companyId'
  | 'complianceCategoryId'
  | 'complianceCategoryName'
  | 'createDate'
  | 'inBetweenPricing'
  | 'indexOrder'
  | 'isBatchTracked'
  | 'isGiftCard'
  | 'isMasterProduct'
  | 'isPricingGroup'
  | 'metaData'
  | 'overridePrices'
  | 'parentCategoryId'
  | 'parentCategoryName'
  | 'parentProductId'
  | 'parentProductName'
  | 'supplierName'
  | 'taxes'
  | 'taxGroupName'
  | 'updateDate'
  | 'wooCommerceProductId'
  | 'wooCommerceSyncDate'
  | 'cannabinoidMetaData'
  | 'tags'

interface IProductNetworkObject extends Omit<ProductModels.INestedProduct, TOmitBaseProduct> {
  id: string | null
  overridePrices: CreateOverridePrice[]
  metaData: ProductMetaDataItem[]
  cannabinoidMetaData: CannabinoidMetaDataItem[]
  tags: (TagModels.ICreateTagContract | TagModels.ITagContract)[]
}

interface INestedProductNetworkObject
  extends IProductNetworkObject,
    Pick<
      ProductModels.INestedProduct,
      'isMasterProduct' | 'wooCommerceProductId' | 'inBetweenPricing' | 'isBatchTracked'
    > {
  childProducts: IProductNetworkObject[]
}

export enum MetaDataReservedKeys {
  THC = 'thc',
  CBD = 'cbd',
  MAX_CBD = 'maxCBD',
  MIN_CBD = 'minCBD',
  MAX_THC = 'maxTHC',
  MIN_THC = 'minTHC',
  SHOW_MAX_MIN = 'showMaxMin',
  UNIT = 'unit',
}

export const lowerCasedReservedKeys: string[] = Object.keys(MetaDataReservedKeys).map((key) => {
  const lowerCasedKeyValue: string = MetaDataReservedKeys[key].toLowerCase()

  return lowerCasedKeyValue
})

export class ProductMetaData {
  @observable
  metaData: ProductMetaDataItem[] = []

  @action
  addMetaDataItem = (metaDataItem: ProductMetaDataItem) => {
    this.metaData.push(metaDataItem)
  }

  @action
  setValue = (key: string, value: string | number | boolean | null) => {
    const metaDataItem = this.findMetaData(key)
    const val = value?.toString() || null

    if (metaDataItem) {
      metaDataItem.setValue(val)
    } else {
      const newMetaDataItem = new ProductMetaDataItem(key, val)
      this.addMetaDataItem(newMetaDataItem)
    }
  }

  @action
  removeMetaDataItem = (metaDataItem: ProductMetaDataItem) => {
    this.metaData = this.metaData.filter((item) => {
      return item.metaKey !== metaDataItem.metaKey
    })
  }

  @action
  toggleShowMaxMin = () => {
    const showMaxMinItem = this.findMetaData(MetaDataReservedKeys.SHOW_MAX_MIN)

    if (showMaxMinItem || showMaxMinItem === undefined) {
      if (showMaxMinItem === undefined) {
        this.setValue(MetaDataReservedKeys.SHOW_MAX_MIN, true)
      } else {
        showMaxMinItem.setValue(showMaxMinItem.metaValue === 'true' ? 'false' : 'true')
      }

      if (!this.thc && this.maxTHC) {
        this.setValue(MetaDataReservedKeys.THC, this.maxTHC || null)
      }
      if (!this.cbd && this.maxCBD) {
        this.setValue(MetaDataReservedKeys.CBD, this.maxCBD || null)
      }
    } else {
      this.setValue(MetaDataReservedKeys.SHOW_MAX_MIN, false)

      if (!this.maxTHC && this.thc) {
        this.setValue(MetaDataReservedKeys.MAX_THC, this.thc || null)
        this.setValue(MetaDataReservedKeys.MIN_THC, this.thc || null)
      }

      if (!this.maxCBD && this.cbd) {
        this.setValue(MetaDataReservedKeys.MAX_CBD, this.cbd || null)
        this.setValue(MetaDataReservedKeys.MIN_CBD, this.cbd || null)
      }
    }
  }

  @computed
  get unit() {
    const item = this.findMetaData(MetaDataReservedKeys.UNIT)

    return item ? (item.metaValue as CBD_THC_UNIT) : null
  }

  @computed
  get showMaxMin() {
    const item = this.findMetaData(MetaDataReservedKeys.SHOW_MAX_MIN)
    return !!item?.formattedBoolean
  }

  @computed
  get thc() {
    const item = this.findMetaData(MetaDataReservedKeys.THC)
    return item?.formattedNumber === undefined ? null : item.formattedNumber
  }

  @computed
  get minTHC() {
    const item = this.findMetaData(MetaDataReservedKeys.MIN_THC)
    return item?.formattedNumber === undefined ? null : item.formattedNumber
  }

  @computed
  get maxTHC() {
    const item = this.findMetaData(MetaDataReservedKeys.MAX_THC)
    return item?.formattedNumber === undefined ? null : item.formattedNumber
  }

  @computed
  get cbd() {
    const item = this.findMetaData(MetaDataReservedKeys.CBD)
    return item?.formattedNumber === undefined ? null : item.formattedNumber
  }

  @computed
  get minCBD() {
    const item = this.findMetaData(MetaDataReservedKeys.MIN_CBD)
    return item?.formattedNumber === undefined ? null : item.formattedNumber
  }

  @computed
  get maxCBD() {
    const item = this.findMetaData(MetaDataReservedKeys.MAX_CBD)
    return item?.formattedNumber === undefined ? null : item.formattedNumber
  }

  findMetaData = (key: string) =>
    this.metaData.find((item) => {
      return item.metaKey.toLowerCase() === key.toLowerCase()
    })
}

export class ProductMetaDataItem implements IProductMetaDataItem {
  @observable
  metaKey = ''

  @observable
  metaValue: string | null = null

  constructor(metaKey?: string, metaValue?: string | null) {
    this.metaKey = metaKey || ''
    this.metaValue = metaValue || null
  }

  @action
  setKey = (key: string) => {
    this.metaKey = key
  }

  @action
  setValue = (value: string | null) => {
    this.metaValue = value
  }

  @computed
  get formattedNumber() {
    if (!lowerCasedReservedKeys.includes(this.metaKey.toLowerCase())) return

    const nonFloatKeys = [MetaDataReservedKeys.UNIT, MetaDataReservedKeys.SHOW_MAX_MIN]

    if (nonFloatKeys.some((key) => key === this.metaKey)) return

    if (this.metaValue === null) return

    try {
      return parseFloat(this.metaValue)
    } catch (err) {
      return
    }
  }

  @computed
  get formattedBoolean() {
    if (this.metaKey === MetaDataReservedKeys.SHOW_MAX_MIN) {
      return this.metaValue === 'true'
    }
  }
}

export class CannabinoidMetaData {
  @observable
  cannabinoids: CannabinoidMetaDataItem[] = []

  @action
  addCannabinoid = (unit: CBD_THC_UNIT | null, showMaxMin: boolean) => {
    const cannabinoid = new CannabinoidMetaDataItem()

    cannabinoid.setValue(MetaDataReservedKeys.SHOW_MAX_MIN, showMaxMin)
    cannabinoid.setValue(MetaDataReservedKeys.UNIT, unit || CBD_THC_UNIT.PERCENT)

    this.cannabinoids.push(cannabinoid)
  }

  @action
  removeCannabinoid = (index: number) => {
    this.cannabinoids.splice(index, 1)
  }

  get locationMappedCannabinoidMetaData() {
    const mappedCannabinoidMetaData = new Map<number, CannabinoidMetaDataItem>()

    this.cannabinoids
      .filter((cnb) => cnb.isLocationCannabinoid !== false)
      .forEach((cnb) => {
        cnb.locationIds.forEach((locationId) => {
          mappedCannabinoidMetaData.set(locationId, cnb)
        })
      })

    return mappedCannabinoidMetaData
  }

  get batchMappedCannabinoidMetaData() {
    const mappedCannabinoidMetaData = new Map<string, CannabinoidMetaDataItem>()

    this.cannabinoids.forEach((cnb) => {
      if (cnb.batchNumber) {
        mappedCannabinoidMetaData.set(cnb.batchNumber, cnb)
      }
    })

    return mappedCannabinoidMetaData
  }
}

// At the time of profile creation, there is no `productId`
interface ICannabinoidMetaDataItem
  extends Omit<LotModels.ICreateLotContract, 'productId' | 'companyId'> {
  id?: number
}

export class CannabinoidMetaDataItem extends ProductMetaData implements ICannabinoidMetaDataItem {
  id?: number

  @observable
  batchNumber?: string

  @observable
  locationIds: number[] = []

  @observable
  isLocationCannabinoid?: boolean

  setLocationIds = (locationIds: number[]) => {
    this.locationIds = locationIds
  }

  setBatchNumber = (batchNumber: string | undefined) => {
    this.batchNumber = batchNumber
  }

  @action
  addLocationId = (locationId: number) => {
    this.locationIds.push(locationId)
  }

  @action
  removeLocationId = (locationId: number) => {
    this.locationIds = this.locationIds.filter((locId) => locId !== locationId)
  }

  @action
  populate = (lotContract: ILotContract, defaultMetaData: IProductMetaData) => {
    this.id = lotContract.id
    this.batchNumber = lotContract.batchNumber
    this.locationIds = lotContract.locationIds
    this.isLocationCannabinoid =
      lotContract.isLocationCannabinoid !== null ? lotContract.isLocationCannabinoid : undefined
    this.populateMetaData(lotContract.metaData, defaultMetaData)
  }

  private formatMetaDataValue = (value: number | null) =>
    value === null ? 'unset' : `${value}${this.unit || CBD_THC_UNIT.PERCENT}`

  @computed
  get thcRange() {
    return this.showMaxMin
      ? `${this.formatMetaDataValue(this.minTHC)} - ${this.formatMetaDataValue(this.maxTHC)}`
      : `${this.formatMetaDataValue(this.thc)}`
  }

  @computed
  get cbdRange() {
    return this.showMaxMin
      ? `${this.formatMetaDataValue(this.minCBD)} - ${this.formatMetaDataValue(this.maxCBD)}`
      : `${this.formatMetaDataValue(this.cbd)}`
  }

  @computed
  get thcCbdRangeInfo() {
    return `THC: ${this.thcRange} | CBD: ${this.cbdRange}`
  }

  @action
  populateMetaData = (
    metaData: LotModels.ILotMetaDataContract[],
    defaultMetaData: IProductMetaData,
  ) => {
    const populatedMetaData: ProductMetaDataItem[] = metaData.map((item) => {
      return new ProductMetaDataItem(item.metaKey, item.metaValue)
    })

    if (
      populatedMetaData.some((x) => x.metaKey === MetaDataReservedKeys.UNIT) &&
      populatedMetaData.length > 0
    ) {
      this.metaData = populatedMetaData
      return
    }
    populatedMetaData.push(
      new ProductMetaDataItem(
        MetaDataReservedKeys.UNIT,
        defaultMetaData[MetaDataReservedKeys.UNIT],
      ),
    )

    this.metaData = populatedMetaData
  }
}

class MarginMarkupCalculator {
  @observable
  margin = 0

  @observable
  markup = 0

  private DECIMAL_PLACE = 1

  protected calculateMargin = ({
    price,
    purchasePrice,
    margin,
  }: {
    price: number | null
    purchasePrice: number | null
    margin?: number
  }) => {
    if (margin === undefined) {
      const sellPrice = price || 0
      const cost = purchasePrice || 0
      const calculatedMargin = sellPrice === 0 ? 0 : ((sellPrice - cost) / sellPrice) * 100

      return Number(calculatedMargin.toFixed(this.DECIMAL_PLACE))
    } else if (margin > 100) {
      return 100
    } else {
      return Number(margin.toFixed(this.DECIMAL_PLACE))
    }
  }

  protected calculateMarkup = ({
    price,
    purchasePrice,
    markup,
  }: {
    price: number | null
    purchasePrice: number | null
    markup?: number
  }) => {
    if (markup === undefined) {
      const sellPrice = price || 0
      const cost = purchasePrice || 0
      const calculatedMarkup = cost === 0 ? 0 : ((sellPrice - cost) / cost) * 100

      return Number(calculatedMarkup.toFixed(this.DECIMAL_PLACE))
    } else {
      return Number(markup.toFixed(this.DECIMAL_PLACE))
    }
  }

  protected calculatePriceFromMargin = (margin: number, purchasePrice: number | null) => {
    /**
     * If margin is 100%, this causes the denominator to become 0.
     * Since numerator / 0 is infinity, set margin to 99.99
     */
    const denominator = 1 - (margin === 100 ? 99.99 : margin) / 100

    const price = (purchasePrice || 0) / denominator

    return Math.round(price)
  }

  protected calculatePriceFromMarkup = (markup: number, purchasePrice: number | null) => {
    const price = (markup / 100 + 1) * (purchasePrice || 0)

    return Math.round(price)
  }
}

export class CreateOverridePrice
  extends MarginMarkupCalculator
  implements ICreateMultiLocationPrice
{
  @observable
  locationId: number

  @observable
  productId: string | null = null

  @observable
  retailPrice: number | null = null

  @observable
  wholesaleCost: number | null = null

  constructor(overridePrice: ICreateMultiLocationPrice, purchasePrice: number | null) {
    super()

    this.locationId = overridePrice.locationId
    this.retailPrice = overridePrice.retailPrice
    this.wholesaleCost = overridePrice.wholesaleCost
    this.productId = overridePrice.productId

    this.setMargin(purchasePrice)
    this.setMarkup(purchasePrice)
  }

  @action
  setLocationId(locationId: number) {
    this.locationId = locationId
  }

  @action
  setProductId(productId: string | null) {
    this.productId = productId
  }

  @action
  setRetailPrice(retailPrice: number | null) {
    this.retailPrice = retailPrice
  }

  @action
  setWholesaleCost(wholesaleCost: number | null) {
    this.wholesaleCost = wholesaleCost === 0 ? null : wholesaleCost
  }

  @action
  setMargin = (purchasePrice?: number | null, margin?: number) => {
    const cost = purchasePrice || this.wholesaleCost || 0

    this.margin = this.calculateMargin({
      price: this.retailPrice,
      purchasePrice: cost,
      margin,
    })

    if (margin !== undefined) {
      this.retailPrice = this.calculatePriceFromMargin(this.margin, cost)
      this.setMarkup(cost)
    }
  }

  @action
  setMarkup = (purchasePrice?: number | null, markup?: number) => {
    const cost = purchasePrice || this.wholesaleCost || 0

    this.markup = this.calculateMarkup({
      price: this.retailPrice,
      purchasePrice: cost,
      markup,
    })

    if (markup !== undefined) {
      this.retailPrice = this.calculatePriceFromMarkup(this.markup, cost)
      this.setMargin(cost)
    }
  }
}

export class CreateBaseProduct extends MarginMarkupCalculator {
  // True if any field has been modified after initialization, used to limit barcode printing without saves
  @observable
  changed = false

  @observable
  imageUrl: string | null = null

  @observable
  sku: string | null = null

  @observable
  barcode: string | null = null

  @observable
  name = ''

  @observable
  shortName = ''

  @observable
  description = ''

  @observable
  shortDescription = ''

  @observable
  inventorySubtractAmount = 1

  @observable
  unit: Unit = 'unit'

  @observable
  price: number | null = 0

  @observable
  purchasePrice: number | null = null

  @observable
  depositFee: number | null = null

  @observable
  weight: number | null = null

  @observable
  cannabisWeight: number | null = null

  @observable
  cannabisVolume: number | null = null

  @observable
  categoryId: string | null = null

  @observable
  supplierId: number | null = null

  @observable
  taxGroupId: number | null = null

  @observable
  trackInventory = true

  @observable
  pricingGroupId: string | null = null

  originalPricingGroupId: string | null = null

  @observable
  isActive = true

  @observable
  isCumulative = false

  @observable
  trackPurchaseOrderCosts = true

  @observable
  trackConversionCosts = true

  @observable
  thc: number | null = null

  @observable
  cbd: number | null = null

  @observable
  overridePrices: CreateOverridePrice[] = []

  @observable
  metaData = new ProductMetaData()

  @observable
  cannabinoidMetaData = new CannabinoidMetaData()

  // this field will be used to determine whether to display warning message or not
  @observable
  isDeleted = false

  @observable
  isGiftCard = false

  @observable
  productType = ProductType.PRODUCT

  createDate?: string

  @observable
  strainType: EProductStrainType | null = null

  @observable
  tags: (TagModels.ICreateTagContract | TagModels.ITagContract)[] = []

  @action
  setTags(tags: (TagModels.ICreateTagContract | TagModels.ITagContract)[]) {
    this.tags = tags
  }

  @action
  removePriceOverride(overridePriceToRemove: CreateOverridePrice) {
    const newOverridePrices = this.overridePrices.filter(
      (overridePrice) => overridePrice.locationId !== overridePriceToRemove.locationId,
    )
    this.overridePrices = newOverridePrices
  }

  @computed
  get overrideLocationIds() {
    return this.overridePrices.map((overridePrices) => overridePrices.locationId)
  }

  @action
  setSKU(sku: string | null) {
    if (!sku) {
      sku = null
    }
    const cleanedSku = sku ? sku.replace(/\u200B/g, '') : null
    this.sku = cleanedSku
  }

  @action
  setBarcode(barcode: string | null) {
    if (!barcode) {
      barcode = null
    }
    const cleanedBarcode = barcode ? barcode.replace(/\u200B/g, '') : null
    this.barcode = cleanedBarcode
  }

  @action
  setName(name: string) {
    this.name = name
  }

  @action
  setShortName(shortName: string) {
    this.shortName = shortName
  }

  @action
  setDescription(description: string) {
    this.description = description
  }

  @action
  setShortDescription(shortDescription: string) {
    this.shortDescription = shortDescription
  }

  @action
  setInventorySubtractAmount(inventorySubtractAmount: number) {
    this.inventorySubtractAmount = inventorySubtractAmount
  }

  @action
  setPrice(price: number | null) {
    this.price = price

    this.setMargin()
    this.setMarkup()
  }

  @action
  setDepositFee(fee: number | null) {
    if (!fee) {
      fee = null
    }
    this.depositFee = fee
  }

  @action
  setPurchasePrice(price: number | null) {
    if (!price) {
      price = null
    }
    this.purchasePrice = price

    this.setMargin()
    this.setMarkup()
  }

  @action
  setMargin = (margin?: number) => {
    this.margin = this.calculateMargin({
      price: this.price,
      purchasePrice: this.purchasePrice,
      margin,
    })

    if (this.isGiftCard) {
      this.margin = 0
    }

    if (margin !== undefined) {
      this.price = this.calculatePriceFromMargin(this.margin, this.purchasePrice)
      this.setMarkup()
    }
  }

  @action
  setMarkup = (markup?: number) => {
    this.markup = this.calculateMarkup({
      price: this.price,
      purchasePrice: this.purchasePrice,
      markup,
    })

    if (this.isGiftCard) {
      this.markup = 0
    }

    if (markup !== undefined) {
      this.price = this.calculatePriceFromMarkup(this.markup, this.purchasePrice)
      this.setMargin()
    }
  }

  @action
  setWeight(weight: number | null) {
    if (!weight) {
      weight = null
    }
    this.weight = weight
  }

  @action
  setCannabisWeight(cannabisWeight: number | null) {
    if (!cannabisWeight) {
      cannabisWeight = null
    }
    this.cannabisWeight = cannabisWeight
  }

  @action
  setCannabisVolume(cannabisVolume: number | null) {
    if (!cannabisVolume) {
      cannabisVolume = null
    }
    this.cannabisVolume = cannabisVolume
  }

  @action
  setTrackPurchaseOrderCosts(trackPurchaseOrderCosts: boolean) {
    this.trackPurchaseOrderCosts = trackPurchaseOrderCosts
  }

  @action
  setTrackConversionCosts(trackConversionCosts: boolean) {
    this.trackConversionCosts = trackConversionCosts
  }

  @action
  setStrainType(strainType: EProductStrainType | null) {
    this.strainType = strainType
  }

  // Reference: https://github.com/darkskyapp/string-hash/blob/master/index.js
  @action
  generateBarcode() {
    let input = this.name
    input += new Date().toString()
    let hash = 5381
    let i = 100

    while (i) {
      hash = (hash * 33) ^ input.charCodeAt(--i)
    }

    hash = hash >>> 0
    let barcode = hash.toString()
    while (barcode.length < 11) {
      barcode += '0'
    }
    this.barcode = barcode
  }

  @computed
  get isFreePricing(): boolean {
    return this.price === null
  }
}

export class CreateProduct extends CreateBaseProduct {
  // If ID exists, the product is being edited. Otherwise, it is being created
  @observable
  readonly id: string | null = null

  @observable
  showAdvancedFields = false

  @observable
  isMasterProduct = true

  @observable
  childProducts: CreateChildProduct[] = []

  @observable
  wooCommerceProductId: number | null = null

  // this is used to determine how to display variants
  @observable
  needsPricingGroupRefresh = false

  @observable
  inBetweenPricing = false

  @observable
  isBatchTracked = false

  constructor() {
    super()
    // Force inherited property consistencies
    this.setInheritedProperties()
    setTimeout(() => {
      observe(this, (change) => {
        if (change.name !== 'changed') {
          this.changed = true
        }
      })
    }, 1000)
  }

  @computed
  get inventoryMode(): boolean | 'bulk' | 'prepack' | undefined {
    if (this.isMasterProduct) {
      let temp: boolean | undefined = this.childProducts.length > 0 ? true : false
      this.childProducts.forEach((cp) => {
        if (cp.trackInventory === false) {
          temp = false
        }
      })
      if (this.childProducts.find((cp) => cp.trackInventory !== temp)) {
        temp = undefined
      }
      const variantsTrackInventory = temp
      if (variantsTrackInventory === undefined) {
        return undefined
      } else if (this.trackInventory === false && !variantsTrackInventory) {
        return false
      } else if (this.trackInventory === false && variantsTrackInventory) {
        return 'prepack'
      } else if (this.trackInventory === true && !variantsTrackInventory) {
        return 'bulk'
      } else {
        return undefined
      }
    } else {
      return this.trackInventory
    }
  }

  @action
  setInventoryMode(mode: boolean | 'bulk' | 'prepack') {
    if (mode === true || mode === false) {
      this.trackInventory = mode
      if (mode === true) {
        this.isMasterProduct = false
      }
      if (mode === false) {
        this.childProducts.forEach((cp) => {
          cp.trackInventory = false
        })
      }
    } else if (mode === 'bulk') {
      this.isMasterProduct = true
      this.trackInventory = true
      this.childProducts.forEach((cp) => {
        cp.trackInventory = false
      })
      this.setIsCumulative(false)
    } else if (mode === 'prepack') {
      this.isMasterProduct = true
      this.trackInventory = false
      this.setUnit('unit') // This will also set the inventory subtracted of parent/variants to be 1 as a side-effecet
      this.childProducts.forEach((cp) => {
        cp.trackInventory = true
      })
    }
  }

  @action
  unsetChanged() {
    this.childProducts.forEach((p) => {
      p.changed = false
    })
    this.changed = false
  }

  @computed
  get networkObject(): INestedProductNetworkObject {
    return {
      id: this.id,
      imageUrl: this.imageUrl,
      sku: this.sku ? this.sku.trim() : null,
      barcode: this.barcode,
      name: this.name.trim(),
      shortName: this.shortName,
      description: this.description,
      shortDescription: this.shortDescription,
      inventorySubtractAmount: this.inventorySubtractAmount,
      unit: this.unit,
      price: this.price,
      depositFee: this.depositFee,
      purchasePrice: this.purchasePrice,
      weight: this.weight,
      cannabisWeight: this.cannabisWeight,
      cannabisVolume: this.cannabisVolume,
      categoryId: this.categoryId,
      supplierId: this.supplierId,
      taxGroupId: this.taxGroupId,
      trackInventory: this.trackInventory,
      isMasterProduct: this.isMasterProduct,
      childProducts: this.childProducts.map((cp) => cp.networkObject),
      wooCommerceProductId: this.wooCommerceProductId,
      pricingGroupId: this.pricingGroupId,
      inBetweenPricing: this.inBetweenPricing,
      isActive: this.isActive,
      trackPurchaseOrderCosts: this.trackPurchaseOrderCosts,
      trackConversionCosts: this.trackConversionCosts,
      isBatchTracked: this.isBatchTracked,
      thc: this.thc,
      cbd: this.cbd,
      isCumulative: this.isCumulative,
      overridePrices: this.overridePrices,
      metaData: this.metaData.metaData,
      cannabinoidMetaData: this.cannabinoidMetaData.cannabinoids,
      isDeleted: this.isDeleted,
      productType: this.productType,
      strainType: this.strainType,
      tags: this.tags,
    }
  }

  @action
  populateFields(jsonFromAPI: any) {
    const { childProducts, ...restOfObject } = jsonFromAPI

    Object.assign(this, restOfObject)

    this.setMargin()
    this.setMarkup()

    this.originalPricingGroupId = restOfObject.pricingGroupId

    this.overridePrices = this.overridePrices.map(
      (overridePrice) => new CreateOverridePrice(overridePrice, this.purchasePrice),
    )

    const metaDataObj = new ProductMetaData()

    Object.keys(this.metaData).map((key) => {
      if (typeof this.metaData[key] === 'string') {
        const metaDataItem = new ProductMetaDataItem(key, this.metaData[key])
        metaDataObj.addMetaDataItem(metaDataItem)
      }
    })

    this.metaData = metaDataObj

    this.cannabinoidMetaData = this.getCannabinoidMetaData(restOfObject)

    this.childProducts = childProducts.map((childProduct: any) => {
      const observableChildProduct = new CreateChildProduct(this)

      Object.assign(observableChildProduct, childProduct)

      observableChildProduct.setMargin()
      observableChildProduct.setMarkup()

      if (childProduct.overridePrices) {
        const observableOverridePrices = childProduct.overridePrices.map(
          (overridePrice: CreateOverridePrice) => {
            return new CreateOverridePrice(overridePrice, childProduct.purchasePrice)
          },
        )

        Object.assign(observableChildProduct, { overridePrices: observableOverridePrices })
      }

      const metaData = new ProductMetaData()

      if (childProduct.metaData) {
        Object.keys(childProduct.metaData).map((key) => {
          const metaDataItem = new ProductMetaDataItem(key, childProduct.metaData[key])
          metaData.addMetaDataItem(metaDataItem)
        })
      }

      Object.assign(observableChildProduct, { metaData })

      observableChildProduct.originalPricingGroupId = childProduct.originalPricingGroupId

      observableChildProduct.cannabinoidMetaData = this.getCannabinoidMetaData(childProduct)

      return observableChildProduct
    })
  }

  getCannabinoidMetaData = (product: IBaseProduct) => {
    const metaData = new CannabinoidMetaData()

    // Fallback of `[]` is required because `populateField` method accepts a parameter of `any` (poor code)
    // This is called in `EditPricingGroupModal` where the api call doesn't contain `cannabinoidMetaData`
    metaData.cannabinoids = (product.cannabinoidMetaData || []).map(
      (cnb: LotModels.ILotContract) => {
        const item = new CannabinoidMetaDataItem()
        item.populate(cnb, product.metaData)
        return item
      },
    )

    return metaData
  }

  @action
  undelete(product: INestedProduct) {
    this.networkObject.isDeleted = false
    this.networkObject.isActive = true
    if (product.isMasterProduct) {
      this.networkObject.childProducts.forEach((child: any) => {
        child.isDeleted = false
        child.isActive = true
      })
    }
    if (product.parentProductId) {
      this.networkObject.childProducts.forEach((child: any) => {
        if (child.id === product.id) {
          child.isDeleted = false
          child.isActive = true
        }
      })
    }
    return this.networkObject
  }

  @action
  toggleAdvancedFields() {
    this.showAdvancedFields = !this.showAdvancedFields
  }

  @action
  setImageUrl(imageUrl: string | null) {
    if (!imageUrl) {
      imageUrl = null
    }
    this.imageUrl = imageUrl
    this.childProducts.forEach((childProduct) => {
      if (!childProduct.imageUrl) {
        childProduct.imageUrl = imageUrl
      }
    })
  }

  @action
  setUnit(unit: Unit) {
    this.unit = unit
    if (unit === 'unit') {
      this.inventorySubtractAmount = 1
    }
    this.childProducts.forEach((childProduct) => {
      childProduct.unit = unit
      if (unit === 'unit') {
        childProduct.inventorySubtractAmount = 1
      }
    })
  }

  @action
  setCategoryId(categoryId: string | null) {
    this.categoryId = categoryId
    this.childProducts.forEach((childProduct) => {
      childProduct.categoryId = categoryId
    })
  }

  @action
  setSupplierId(supplierId: number | null) {
    this.supplierId = supplierId
    this.childProducts.forEach((childProduct) => {
      childProduct.supplierId = supplierId
    })
  }

  @action
  setTaxGroupId(taxGroupId: number | null) {
    this.taxGroupId = taxGroupId
    this.childProducts.forEach((childProduct) => {
      childProduct.taxGroupId = taxGroupId
    })
  }

  @action
  setTrackInventory(trackInventory: boolean) {
    this.trackInventory = trackInventory
    if (trackInventory) {
      this.childProducts.forEach((childProduct) => {
        childProduct.trackInventory = false
      })
    }
  }

  @action
  setIsMasterProduct(isMasterProduct: boolean) {
    this.isMasterProduct = isMasterProduct
    if (isMasterProduct) {
      this.price = 0

      // Default is prepack
      if (this.childProducts.length === 0) {
        this.addChildProduct()
        this.setInventoryMode('prepack')
      }
    } else {
      // Clear the child products array if the product is updated to not be a master product
      this.childProducts = []
      this.setInventoryMode(true)
    }
  }

  @action
  addChildProduct(initialFields?: IChildProduct) {
    const childProduct = new CreateChildProduct(this)
    if (initialFields) {
      Object.assign(childProduct, initialFields)
      childProduct.id = null
      childProduct.sku = null
      childProduct.imageUrl = this.imageUrl
    }
    childProduct.unit = this.unit
    childProduct.categoryId = this.categoryId
    childProduct.supplierId = this.supplierId
    childProduct.taxGroupId = this.taxGroupId
    if (this.trackInventory) {
      childProduct.trackInventory = false
    } else {
      childProduct.trackInventory = true
    }
    this.childProducts.push(childProduct)
    this.isActive = true
    return childProduct
  }

  @action
  removeChildProduct(index: number) {
    this.childProducts.splice(index, 1)
    this.updateActiveStatus()
  }

  @action
  clearAllChildProducts() {
    this.childProducts = []
  }

  @action
  setWooCommerceProductId(wooCommerceProductId: number | null) {
    if (!wooCommerceProductId) {
      wooCommerceProductId = null
    }
    this.wooCommerceProductId = wooCommerceProductId
  }

  @action
  setPricingGroupId(pricingGroupId: string | null) {
    const newPricingGroupDiffFromThis = this.pricingGroupId !== pricingGroupId
    const newPricingGroupDiffFromOriginal = this.originalPricingGroupId !== pricingGroupId

    if (
      pricingGroupId &&
      newPricingGroupDiffFromThis &&
      newPricingGroupDiffFromOriginal &&
      this.id
    ) {
      this.needsPricingGroupRefresh = true
    } else if (!pricingGroupId) {
      this.needsPricingGroupRefresh = false
      this.childProducts.forEach((cp) => {
        cp.pricingGroupId = null
      })
    } else {
      this.needsPricingGroupRefresh = false
    }

    this.pricingGroupId = pricingGroupId
  }

  @action
  setInBetweenPricing(inBetweenPricing: boolean) {
    this.inBetweenPricing = inBetweenPricing
  }

  @action
  setIsActive(isActive: boolean) {
    this.isActive = isActive
    this.childProducts.forEach((cp) => {
      cp.setIsActive(isActive)
    })
  }

  @action
  setIsCumulative(isCumulative: boolean) {
    this.isCumulative = isCumulative
    this.childProducts.forEach((cp) => {
      cp.setIsCumulative(isCumulative)
    })
  }

  @action
  setIsBatchTracked(isBatchTracked: boolean) {
    this.isBatchTracked = isBatchTracked
  }

  @action
  async setInheritedProperties(currentCompanyStore?: CurrentCompanyStore) {
    // Clean parent product SKU
    if (!this.sku) {
      this.sku = null
    }
    for (const childProduct of this.childProducts) {
      childProduct.unit = this.unit
      childProduct.supplierId = this.supplierId
      childProduct.categoryId = this.categoryId
      childProduct.taxGroupId = this.taxGroupId
      if (!childProduct.imageUrl) {
        childProduct.imageUrl = this.imageUrl
      }
      if (childProduct.imageUrl && !childProduct.imageUrl.includes(CLOUDINARY)) {
        try {
          childProduct.imageUrl = await uploadImageAndGetCloudinaryUrl(
            childProduct.imageUrl,
            currentCompanyStore!,
          )
        } catch (error) {
          childProduct.imageUrl = null
          console.log(error)
        }
      }

      if (this.isMasterProduct && this.trackInventory) {
        childProduct.trackInventory = false
      }
      if (!childProduct.sku) {
        childProduct.sku = null
      }

      /*
      When a customer removes an input value inside <CurrencyInput />, it returns `null` instead of 0.
      We don't want child products' prices to be null, so this should be manually set to 0.
      */
      if (!childProduct.price) {
        childProduct.setPrice(0)
      }
    }
  }

  @action
  updateActiveStatus = () => {
    if (this.childProducts.length === 0) {
      return
    }

    const firstActiveVariant = this.childProducts.find((child) => child.isActive)
    this.isActive = firstActiveVariant !== undefined
  }
}

export class CreateChildProduct extends CreateBaseProduct {
  id: string | null = null

  parentProduct: CreateProduct

  constructor(parentProduct: CreateProduct) {
    super()
    this.parentProduct = parentProduct
    setTimeout(() => {
      observe(this, () => {
        this.changed = true
        this.parentProduct.changed = true
      })
    }, 1000)
  }

  @computed
  get networkObject(): IProductNetworkObject {
    return {
      id: this.id,
      imageUrl: this.imageUrl,
      sku: this.sku ? this.sku.trim() : null,
      barcode: this.barcode,
      name: this.name.trim(),
      shortName: this.shortName,
      description: this.description,
      shortDescription: this.shortDescription,
      inventorySubtractAmount: this.inventorySubtractAmount,
      unit: this.unit,
      price: this.price,
      depositFee: this.depositFee,
      purchasePrice: this.purchasePrice,
      weight: this.weight,
      cannabisWeight: this.cannabisWeight,
      cannabisVolume: this.cannabisVolume,
      categoryId: this.categoryId,
      supplierId: this.supplierId,
      taxGroupId: this.taxGroupId,
      trackInventory: this.trackInventory,
      pricingGroupId: this.pricingGroupId,
      isActive: this.isActive,
      trackPurchaseOrderCosts: this.trackPurchaseOrderCosts,
      trackConversionCosts: this.trackConversionCosts,
      thc: this.thc,
      cbd: this.cbd,
      overridePrices: this.overridePrices,
      metaData: this.metaData.metaData,
      cannabinoidMetaData: this.cannabinoidMetaData.cannabinoids,
      isDeleted: this.isDeleted,
      productType: this.productType,
      strainType: this.strainType,
      isCumulative: this.isCumulative,
      tags: this.tags,
    }
  }

  @action
  setImageUrl(imageUrl: string | null) {
    if (!imageUrl) {
      imageUrl = null
    }
    this.imageUrl = imageUrl
  }

  @action
  setTrackInventory(trackInventory: boolean) {
    this.trackInventory = trackInventory
    if (trackInventory) {
      this.parentProduct.trackInventory = false
    }
  }

  @action
  setPricingGroupId(pricingGroupId: string | null) {
    this.pricingGroupId = pricingGroupId
  }

  @action
  setIsActive(isActive: boolean) {
    this.isActive = isActive
    this.parentProduct.updateActiveStatus()
  }

  @action
  setIsCumulative(isCumulative: boolean) {
    this.isCumulative = isCumulative
  }

  @computed
  get getPrice(): number | null {
    return this.price
  }
}
