import { observable, computed, action } from 'mobx'
import {
  ICompany,
  IPaginatedResponse,
  ICategoryContract,
  IBaseCategory,
  IComplianceCategoryContract,
  IProductSearchFilter,
  CreateTaxGroup,
  UpdateTaxGroup,
  API,
  Supplier,
} from '@getgreenline/homi-shared'

import { throttle } from 'lodash'
import { ProductFilter } from '../../containers/Dashboard/DashboardProducts/ProductListing/ProductFilter'
import {
  productApi,
  discountApi,
  TaxModels,
  taxApi,
  DiscountModels,
  ProductModels,
} from '@getgreenline/products'
import { CategoriesModels } from '@getgreenline/categories'

export class ProductStore {
  @observable company: ICompany
  @observable suppliers?: Supplier[]
  @observable categories?: ICategoryContract[]
  @observable complianceCategories?: IComplianceCategoryContract[]
  @observable discounts?: DiscountModels.IDiscount[]
  @observable paginatedDiscounts?: IPaginatedResponse<DiscountModels.IDiscount>
  @observable nestedProducts?: ProductModels.INestedProduct[]
  @observable inventoryProducts?: ProductModels.IBaseProduct[]
  @observable inactiveNestedProducts?: ProductModels.INestedProduct[]
  @observable deletedNestedProducts?: ProductModels.INestedProduct[]
  @observable productFilter?: ProductFilter
  @observable bundledDiscounts: DiscountModels.IDiscount[] = []

  constructor(company: ICompany) {
    this.company = company
  }

  @computed
  get flattenedProducts() {
    if (!this.nestedProducts) {
      return undefined
    }
    return this.flattenProducts(this.nestedProducts)
  }

  flattenProducts(nestedProducts: ProductModels.INestedProduct[]) {
    const flattenedProducts: Array<ProductModels.IBaseProduct & { parentSKU: string | null }> = []
    nestedProducts.forEach((product) => {
      flattenedProducts.push({ parentSKU: null, ...product })
      product.childProducts.forEach((childProduct) => {
        flattenedProducts.push({ parentSKU: product.sku, ...childProduct })
      })
    })

    return flattenedProducts
  }

  @computed
  get sellableProducts() {
    if (!this.nestedProducts) {
      return undefined
    }

    return this.getSellableProducts(this.nestedProducts)
  }

  getSellableProducts(
    nestedProducts: ProductModels.INestedProduct[],
  ): ProductModels.IBaseProduct[] {
    const sellableProducts: ProductModels.IBaseProduct[] = []
    nestedProducts.forEach((product) => {
      if (product.isMasterProduct) {
        product.childProducts.forEach((childProduct) => {
          sellableProducts.push(childProduct)
        })
      } else {
        sellableProducts.push(product)
      }
    })
    return sellableProducts
  }

  @observable taxGroups?: TaxModels.TaxGroup[]

  @computed
  get flattenedCategories() {
    const categories = this.categories || []
    const flattenedCategories: IBaseCategory[] = []
    categories.forEach((category) => {
      flattenedCategories.push(category)
      category.childCategories.forEach((childCategory) => {
        const clonedChildCategory: IBaseCategory = JSON.parse(JSON.stringify(childCategory))
        flattenedCategories.push(clonedChildCategory)
      })
    })
    return flattenedCategories
  }

  @computed
  get childCategories() {
    const categories = this.categories || []
    const childCategories: IBaseCategory[] = []
    categories.forEach((category) => {
      category.childCategories.forEach((childCategory) => {
        const clonedChildCategory: IBaseCategory = JSON.parse(JSON.stringify(childCategory))
        childCategories.push(clonedChildCategory)
      })
    })
    return childCategories
  }
  @action
  async getSuppliers() {
    const suppliers = await API.getSuppliers(this.company.id)
    this.suppliers = suppliers
    return suppliers
  }

  @action
  async getCategories() {
    const categories = await API.getCategories(this.company.id)
    this.categories = categories
    return categories
  }

  @action
  async addCategory(createObject: any) {
    const category = await API.addCategory(this.company.id, createObject)
    this.getCategories()
    return category
  }

  @action
  async updateCategory(categoryId: string, createObject: any) {
    const category = await API.updateCategory(this.company.id, categoryId, createObject)
    this.getCategories()
    return category
  }

  @action
  async updateCategories(updateObject: IBaseCategory[]) {
    const updatedCategories = await API.updateCategories(this.company.id, updateObject)

    await this.getCategories()

    return updatedCategories
  }

  @action
  async updateCategoryOrder(ordering: Array<{ categoryId: string; index: number }>) {
    await API.updateCategoryOrder(this.company.id, ordering)
    this.getCategories()
  }

  @action
  async deleteCategory(categoryId: string, newCategoryId?: string) {
    await API.deleteCategory(this.company.id, categoryId, newCategoryId)
    this.getCategories()
  }

  @action
  async mergeCategory(categoryId: string, newParentCategoryId: string) {
    await API.mergeCategory(this.company.id, categoryId, newParentCategoryId)
    this.getCategories()
  }

  @action
  async getComplianceCategories() {
    const complianceCategories = await API.getComplianceCategories(this.company.id)
    this.complianceCategories = complianceCategories
    return complianceCategories
  }

  // Default product search filter
  productSearchFilters: IProductSearchFilter = {
    searchQuery: '',
    selectedSupplierId: undefined,
    selectedCategoryIds: [],
    offset: 0,
    limit: 50,
  }

  @action
  async getProducts(searchFilters?: IProductSearchFilter) {
    if (searchFilters) {
      this.productSearchFilters = searchFilters
    }

    const paginatedProducts = await productApi.getProducts(
      this.company.id,
      this.productSearchFilters,
    )
    this.nestedProducts = paginatedProducts.products
    this.inventoryProducts = this.flattenProducts(this.nestedProducts).filter(
      (p) => p.trackInventory && p.isActive,
    )
    return paginatedProducts
  }

  @action
  async getInactiveProducts(searchFilters?: IProductSearchFilter) {
    const paginatedProducts = await productApi.getProducts(this.company.id, searchFilters)
    this.inactiveNestedProducts = paginatedProducts.products
    return paginatedProducts
  }

  @action
  async getDeletedProducts(searchFilters?: IProductSearchFilter) {
    const paginatedProducts = await productApi.getProducts(this.company.id, searchFilters)
    this.deletedNestedProducts = paginatedProducts.products
    return paginatedProducts
  }

  @action
  resetProducts() {
    this.nestedProducts = undefined
    this.inactiveNestedProducts = undefined
    this.deletedNestedProducts = undefined
  }

  async getProductById(productId: string) {
    const product = await API.getProductById(this.company.id, productId)
    return product
  }

  @action
  async addProduct(companyId: number, createObject: any) {
    const product = await API.addProduct(companyId, createObject)
    return product
  }

  @action
  async editProduct(
    companyId: number,
    productId: string,
    createObject: any,
    canUseCannabinoidPerLot = false,
  ) {
    const product = await productApi.updateProduct(
      companyId,
      productId,
      createObject,
      canUseCannabinoidPerLot,
    )
    return product
  }

  @action
  async deleteProduct(companyId: number, productId: string) {
    const product = await API.deleteProduct(companyId, productId)
    this.getProducts()
    return product
  }

  @action
  async getTaxGroups() {
    const taxGroups = await taxApi.getTaxGroups(this.company.id)
    this.taxGroups = taxGroups
    return taxGroups
  }

  @action
  async addTaxGroup(createObject: CreateTaxGroup) {
    const taxGroup = await taxApi.addTaxGroup(this.company.id, createObject)
    this.getTaxGroups()
    return taxGroup
  }

  @action
  async editTaxGroup(updateObject: UpdateTaxGroup) {
    const taxGroup = await taxApi.updateTaxGroup(this.company.id, updateObject.id, updateObject)
    this.getTaxGroups()
    return taxGroup
  }

  @action
  async deleteTaxGroup(taxGroupId: number) {
    const taxGroup = await taxApi.deleteTaxGroup(this.company.id, taxGroupId)
    this.getTaxGroups()
    return taxGroup
  }

  @action
  async getDiscounts(searchFilter?: DiscountModels.IDiscountV2Filter) {
    const discounts = await discountApi.getAllDiscountsV2(this.company.id, searchFilter)
    this.discounts = discounts
    return discounts
  }

  @action
  async getPaginatedDiscounts(searchFilter?: DiscountModels.IPaginatedDiscountFilter) {
    const discounts = await discountApi.getDiscountsV2(this.company.id, searchFilter)

    this.paginatedDiscounts = discounts
    return discounts
  }

  // So many components can mount but won't abuse the network
  throttleGetDiscounts = throttle(this.getDiscounts, 2000, { trailing: false })

  @action
  async addDiscount(createObject: DiscountModels.CreateOrEditDiscount) {
    const discount = await discountApi.addDiscount(this.company.id, createObject)
    this.getDiscounts()
    return discount
  }

  @action
  async editDiscount(discountId: number, updateObject: DiscountModels.CreateOrEditDiscount) {
    const discount = await discountApi.updateDiscount(this.company.id, discountId, updateObject)
    this.getDiscounts()
    return discount
  }

  @action
  async deleteDiscount(discountId: number, searchFilter?: DiscountModels.IPaginatedDiscountFilter) {
    await discountApi.deleteDiscount(this.company.id, discountId)

    if (searchFilter) {
      this.getPaginatedDiscounts(searchFilter)
    } else {
      this.getDiscounts()
    }
  }

  @computed
  get mappedCategories(): Map<string, ICategoryContract> {
    return new Map((this.categories || []).map((c) => [c.id, c]))
  }

  @computed
  get mappedFlattenedCategories(): Map<string, IBaseCategory> {
    return new Map((this.flattenedCategories || []).map((c) => [c.id, c]))
  }

  @computed
  get filteredCategories(): ICategoryContract[] {
    return (
      this.categories?.filter(
        (category) => category.categoryType !== CategoriesModels.CategoryType.SYSTEM_FEES,
      ) ?? []
    )
  }

  @computed
  get filteredFlatCategories(): CategoriesModels.IBaseCategory[] {
    return this.flattenedCategories.filter(
      (category) => category.categoryType !== CategoriesModels.CategoryType.SYSTEM_FEES,
    )
  }

  @action
  setBundledDiscounts = (
    bundledDiscounts: DiscountModels.IDiscount[],
  ): DiscountModels.IDiscount[] => {
    this.bundledDiscounts = bundledDiscounts
    return bundledDiscounts
  }
}
