import {
  PaymentStatus,
  PaymentTypeIds,
  API,
  ICompanyWithMetaDataContract,
  ILocationWithMetaDataContract,
  GiftCardType,
  MetaDataType,
  MetaKeys,
  IUserResponse,
} from '@getgreenline/homi-shared'
import { isNil } from 'lodash'
import moment from 'moment'
import { TInventoryOnHandColumns } from '../containers/Dashboard/DashboardReports/Financial/ReportView/InventoryOnHandReport/types'
import { setRootApiUrl } from './environment'
import { EventsV2 } from '@getgreenline/events'
import { isJsonString } from './helpers'

enum LocalStorageKeys {
  AUTH_TOKEN = 'authToken',
  DEVICE_AUTH_TOKEN = 'deviceAuthToken',
  UNAUTHENTICATED_EMAIL = 'unauthenticatedEmail',
  SHOULD_PRINT = 'shouldPrint',
  DEFAULT_PAYMENT_STATUS = 'defaultPaymentStatus',
  AUTO_LOGOUT_AFTER_SALE = 'autoLogoutAfterSale',
  PAYMENT_OPTIONS = 'paymentOptions',
  INVENTORY_FILTER_EMPTY_QTY_HIDDEN = 'inventoryFilterEmptyQtyHidden',
  PRODUCT_LISTINGS_EXTRA_FIELDS = 'productListingsExtraFields',
  PRODUCT_LISTINGS_EXPANDED = 'productListingsExpanded',
  SHOW_LEGAL_DOB = 'showLegalDateOfBirth',
  CASH_FLOAT_LIMIT = 'cashFloatLimit',
  BIRCHMOUNT_API_KEY = 'birchmountApiKey',
  BIRCHMOUNT_ACCESS_TOKEN = 'birchmountAccessToken',
  BIRCHMOUNT_TOKEN_EXPIRY = 'birchmountTokenExpiry',
  BIRCHMOUNT_LOC_ID = 'birchmountLocId',
  SHOULD_PLAY_PARKED_SALE_AUDIO_NOTIFICATION = 'shouldPlayParkedSaleAudioNotification',
  SHOULD_SHOW_PARKED_SALE_TOAST_NOTIFICATION = 'shouldShowParkedSaleToastNotification',
  PARKED_SALE_MODAL_LAST_OPENED = 'parkedSaleModalLastOpened',
  API_URL = 'rootApiUrl',
  OPEN_VARIANTS_BY_DEFAULT = 'openVariantsByDefault',
  INVENTORY_ON_HAND_TABLE_COLUMNS = 'iohTableColumns',
  INVENTORY_LOG_SUMMARY_FILTERS = 'ilsFilters',
  USER_MIGRATING_TO_COGNITO = 'userMigratingToCognito',
  REPORT_QUERY_FILTER = 'reportQueryFilter',
  LOYALTY_POINTS_MULTIPLIER = 'loyaltyPointsMultiplier',
  EVENT_LOG_PARAMS = 'eventLogParams',
}

type PaymentTypeIdKeys = keyof typeof PaymentTypeIds

export type PaymentOptionsEnabled = {
  [index in PaymentTypeIdKeys]: boolean
}

const defaultEnabledPayments: PaymentOptionsEnabled = {
  cash: true,
  credit: true,
  debit: true,
  other: true,
  merrco: false,
  moneris: false,
  global: false,
  etransfer: false,
  merrcoDataCandy: false,
  online: false,
  birchmount: false,
  worldpay: false,
  loyalty: false,
}

export interface IExtraFields {
  supplierName: boolean
  weight: boolean
  cannabisWeight: boolean
  cannabisVolume: boolean
  thc: boolean
  cbd: boolean
  strainType: boolean
  tags: boolean
  barcode: boolean
  createDate: boolean
  updateDate: boolean
  purchasePrice: boolean
}

const defaultExtraFields: IExtraFields = {
  supplierName: true,
  weight: false,
  cannabisWeight: false,
  cannabisVolume: false,
  thc: false,
  cbd: false,
  strainType: false,
  tags: false,
  barcode: true,
  createDate: false,
  updateDate: false,
  purchasePrice: false,
}

export const LocalStorage = {
  setAuthToken: async (authToken: string) =>
    window.localStorage.setItem(LocalStorageKeys.AUTH_TOKEN, authToken),
  clearAuthToken: () => window.localStorage.removeItem(LocalStorageKeys.AUTH_TOKEN),

  getAuthToken: async () => window.localStorage.getItem(LocalStorageKeys.AUTH_TOKEN),
  removeAuthToken: async () => window.localStorage.removeItem(LocalStorageKeys.AUTH_TOKEN),
  removeDeviceToken: async () => window.localStorage.removeItem(LocalStorageKeys.DEVICE_AUTH_TOKEN),

  setUnauthenticatedEmail: async (email: string) =>
    window.sessionStorage.setItem(LocalStorageKeys.UNAUTHENTICATED_EMAIL, email),

  getUnauthenticatedEmail: () =>
    window.sessionStorage.getItem(LocalStorageKeys.UNAUTHENTICATED_EMAIL),

  setUserMigratingToCognito: (userMigratingToCognito: IUserResponse) =>
    window.localStorage.setItem(
      LocalStorageKeys.USER_MIGRATING_TO_COGNITO,
      JSON.stringify(userMigratingToCognito),
    ),

  getUserMigratingToCognito: (): IUserResponse | null => {
    const user = window.localStorage.getItem(LocalStorageKeys.USER_MIGRATING_TO_COGNITO)
    return user ? JSON.parse(user) : null
  },

  clearUserMigrationToCognito: () =>
    window.localStorage.removeItem(LocalStorageKeys.USER_MIGRATING_TO_COGNITO),

  getProductExtraFields: function (): IExtraFields {
    const productExtraFieldsInLocalStorage = window.localStorage.getItem(
      LocalStorageKeys.PRODUCT_LISTINGS_EXTRA_FIELDS,
    )

    if (productExtraFieldsInLocalStorage) {
      return {
        ...defaultExtraFields,
        ...JSON.parse(productExtraFieldsInLocalStorage),
      }
    }

    return defaultExtraFields
  },

  setProductExtraFields: function (extraFieldsSelected: IExtraFields) {
    window.localStorage.setItem(
      LocalStorageKeys.PRODUCT_LISTINGS_EXTRA_FIELDS,
      JSON.stringify(extraFieldsSelected),
    )
  },

  getProductListingExpanded: function () {
    const productListingsExpanded = window.localStorage.getItem(
      LocalStorageKeys.PRODUCT_LISTINGS_EXPANDED,
    )
    return productListingsExpanded === 'true'
  },

  setProductListingExpanded: function (value: boolean) {
    window.localStorage.setItem(LocalStorageKeys.PRODUCT_LISTINGS_EXPANDED, value.toString())
  },

  getOpenVariantsByDefault() {
    const openVariantsByDefault = window.localStorage.getItem(
      LocalStorageKeys.OPEN_VARIANTS_BY_DEFAULT,
    )

    return openVariantsByDefault === null ? true : openVariantsByDefault === 'true'
  },

  setOpenVariantsByDefault(value: boolean) {
    window.localStorage.setItem(LocalStorageKeys.OPEN_VARIANTS_BY_DEFAULT, value.toString())
  },

  SHOULD_SHOW_PARKED_SALE_TOAST_NOTIFICATION:
    LocalStorageKeys.SHOULD_SHOW_PARKED_SALE_TOAST_NOTIFICATION,

  getShowParkedSaleToastNotification: function () {
    const shouldShowToast = window.localStorage.getItem(
      LocalStorageKeys.SHOULD_SHOW_PARKED_SALE_TOAST_NOTIFICATION,
    )

    if (shouldShowToast === null) {
      LocalStorage.setItem(LocalStorage.SHOULD_SHOW_PARKED_SALE_TOAST_NOTIFICATION, 'true')
      return true
    }

    return shouldShowToast === 'true'
  },

  PARKED_SALE_MODAL_LAST_OPENED: LocalStorageKeys.PARKED_SALE_MODAL_LAST_OPENED,
  setParkedSaleModalLastOpened: function () {
    const currentTime = moment.utc()

    window.localStorage.setItem(
      LocalStorageKeys.PARKED_SALE_MODAL_LAST_OPENED,
      currentTime.toString(),
    )
  },

  setShouldPlayParkedSaleAudioNotification: function (value: boolean) {
    window.localStorage.setItem(
      LocalStorageKeys.SHOULD_PLAY_PARKED_SALE_AUDIO_NOTIFICATION,
      value.toString(),
    )
  },

  getShouldPlayParkedSaleAudioNotification: function () {
    const shouldPlayAudio = window.localStorage.getItem(
      LocalStorageKeys.SHOULD_PLAY_PARKED_SALE_AUDIO_NOTIFICATION,
    )
    return shouldPlayAudio === 'true'
  },

  INVENTORY_FILTER_EMPTY_QTY_HIDDEN: LocalStorageKeys.INVENTORY_FILTER_EMPTY_QTY_HIDDEN,

  getInventoryFilterEmptyQtyHidden: function (): boolean {
    const emptyQtyHiddenInLocalStorage = window.localStorage.getItem(
      LocalStorageKeys.INVENTORY_FILTER_EMPTY_QTY_HIDDEN,
    )
    // If emptyQtyHiddenInLocalStorage doesn't exist in local storage, return true
    return emptyQtyHiddenInLocalStorage === null ? true : emptyQtyHiddenInLocalStorage === 'true'
  },

  setInventoryFilterEmptyQtyHidden: function (value: boolean): void {
    window.localStorage.setItem(
      LocalStorageKeys.INVENTORY_FILTER_EMPTY_QTY_HIDDEN,
      value.toString(),
    )
  },

  SHOULD_PRINT: LocalStorageKeys.SHOULD_PRINT,

  getShouldPrint: function (): boolean {
    return window.localStorage.getItem(LocalStorageKeys.SHOULD_PRINT) === 'true'
  },

  DEFAULT_PAYMENT_STATUS: LocalStorageKeys.DEFAULT_PAYMENT_STATUS,

  getDefaultPaymentStatus: function (): PaymentStatus | undefined {
    const rawStringValue = window.localStorage.getItem(
      LocalStorageKeys.DEFAULT_PAYMENT_STATUS,
    ) as PaymentStatus
    if (rawStringValue) {
      return rawStringValue
    } else {
      return undefined
    }
  },

  AUTO_LOGOUT_AFTER_SALE: LocalStorageKeys.AUTO_LOGOUT_AFTER_SALE,
  SHOW_LEGAL_DOB: LocalStorageKeys.SHOW_LEGAL_DOB,

  getAutoLogoutAfterSale: function (): boolean {
    return window.localStorage.getItem(LocalStorageKeys.AUTO_LOGOUT_AFTER_SALE) === 'true'
  },

  getShowLegalDateOfBirth: function (): boolean {
    const storedValue = window.localStorage.getItem(LocalStorageKeys.SHOW_LEGAL_DOB)
    return storedValue ? storedValue === 'true' : true
  },

  setItem: function (key: LocalStorageKeys, value: string): void {
    return window.localStorage.setItem(key, value)
  },

  CASH_FLOAT_LIMIT: LocalStorageKeys.CASH_FLOAT_LIMIT,

  getCashFloatLimit: function (): number | null {
    const value = window.localStorage.getItem(LocalStorageKeys.CASH_FLOAT_LIMIT)
    return value ? parseInt(value) : null
  },

  setCashFloatLimit: function (value: number | null): void {
    value
      ? window.localStorage.setItem(LocalStorageKeys.CASH_FLOAT_LIMIT, value.toString())
      : window.localStorage.removeItem(LocalStorageKeys.CASH_FLOAT_LIMIT)
  },

  PAYMENT_OPTIONS: LocalStorageKeys.PAYMENT_OPTIONS,

  getPaymentOptions: function (): PaymentOptionsEnabled {
    const paymentOptionsString = window.localStorage.getItem(LocalStorageKeys.PAYMENT_OPTIONS)
    const paymentOptions = { ...defaultEnabledPayments }

    if (paymentOptionsString) {
      const parsedPaymentOptions = JSON.parse(paymentOptionsString) as PaymentOptionsEnabled

      for (const paymentOption in parsedPaymentOptions) {
        if (parsedPaymentOptions.hasOwnProperty(paymentOption)) {
          const value = parsedPaymentOptions[paymentOption]
          if (value !== 'undefined') {
            paymentOptions[paymentOption] = value
          }
        }
      }
    }

    return paymentOptions
  },

  togglePaymentOption: function (paymentTypeId: PaymentTypeIds): PaymentOptionsEnabled {
    const currentPaymentOptions = this.getPaymentOptions() as PaymentOptionsEnabled
    const paymentOptionToToggle = PaymentTypeIds[paymentTypeId]
    const currentPaymentOptionValue = currentPaymentOptions[paymentOptionToToggle]

    const newPaymentOptionValue = !currentPaymentOptionValue
    currentPaymentOptions[paymentOptionToToggle] = newPaymentOptionValue

    const stringifiedPaymentOptions = JSON.stringify(currentPaymentOptions)

    this.setItem(LocalStorageKeys.PAYMENT_OPTIONS, stringifiedPaymentOptions)
    return currentPaymentOptions
  },

  setPaymentOption: function (
    paymentTypeId: PaymentTypeIds,
    value: boolean,
  ): PaymentOptionsEnabled {
    const currentPaymentOptions = this.getPaymentOptions() as PaymentOptionsEnabled
    const paymentOptionToToggle = PaymentTypeIds[paymentTypeId]

    const newPaymentOptionValue = value
    currentPaymentOptions[paymentOptionToToggle] = newPaymentOptionValue

    const stringifiedPaymentOptions = JSON.stringify(currentPaymentOptions)

    this.setItem(LocalStorageKeys.PAYMENT_OPTIONS, stringifiedPaymentOptions)
    return currentPaymentOptions
  },

  BIRCHMOUNT_API_KEY: LocalStorageKeys.BIRCHMOUNT_API_KEY,
  setBirchmountApiKey: async function (companyId: number) {
    const companyWithMetaData: ICompanyWithMetaDataContract<MetaDataType.STRING> =
      await API.getCompanyById(companyId, true)

    const birchmountApiKey = companyWithMetaData.metaData[MetaKeys.birchmountApiKey]
    if (isNil(birchmountApiKey)) return

    this.setItem(LocalStorageKeys.BIRCHMOUNT_API_KEY, birchmountApiKey)
  },

  BIRCHMOUNT_ACCESS_TOKEN: LocalStorageKeys.BIRCHMOUNT_ACCESS_TOKEN,
  BIRCHMOUNT_TOKEN_EXPIRY: LocalStorageKeys.BIRCHMOUNT_TOKEN_EXPIRY,
  setBirchmountAccessToken: async function (companyId: number) {
    const birchmountApiKey = window.localStorage.getItem(LocalStorageKeys.BIRCHMOUNT_API_KEY)
    if (isNil(birchmountApiKey)) return

    const response = await API.getGiftCardToken(companyId, GiftCardType.Birchmount)

    this.setItem(LocalStorageKeys.BIRCHMOUNT_ACCESS_TOKEN, response.token)
    this.setItem(LocalStorageKeys.BIRCHMOUNT_TOKEN_EXPIRY, response.expires)
  },

  validateBirchmountToken: async function (companyId: number) {
    const birchmountApiKey = window.localStorage.getItem(LocalStorageKeys.BIRCHMOUNT_API_KEY)
    const birchmountTokenExpiry = window.localStorage.getItem(
      LocalStorageKeys.BIRCHMOUNT_TOKEN_EXPIRY,
    )
    if (isNil(birchmountApiKey) || isNil(birchmountTokenExpiry)) {
      return this.setBirchmountAccessToken(companyId)
    }

    // Check if current token expires in the next 5 minutes
    const currentTime = moment.utc()
    const tokenExpiryTime = moment.utc(birchmountTokenExpiry)
    const difference = tokenExpiryTime.diff(currentTime, 'minutes')

    if (difference <= 5) {
      await this.setBirchmountAccessToken(companyId)
    }
  },

  BIRCHMOUNT_LOC_ID: LocalStorageKeys.BIRCHMOUNT_LOC_ID,
  getBirchmountLocId: function (): number | null {
    const birchmountLocId = window.localStorage.getItem(LocalStorageKeys.BIRCHMOUNT_LOC_ID)
    return birchmountLocId ? parseInt(birchmountLocId) : null
  },

  setBirchmountLocId: async function (companyId: number, locId: number) {
    const allLocations: ILocationWithMetaDataContract<MetaDataType.INTEGER>[] =
      await API.getLocations(companyId, true)
    const location = allLocations.find((loc) => loc.id === locId)
    if (!location) return

    const birchmountLocId = location.metaData[MetaKeys.birchmountLocationId]
    if (isNil(birchmountLocId)) return

    this.setItem(LocalStorageKeys.BIRCHMOUNT_LOC_ID, birchmountLocId.toString())
  },

  getOverrideAPIUrl: (): string | null => {
    return window.localStorage.getItem(LocalStorageKeys.API_URL)
  },

  setOverrideAPIUrl: (url: string | null) => {
    const apiUrl = url || process.env.REACT_APP_ROOT_API_URL!
    setRootApiUrl(apiUrl)
    url
      ? window.localStorage.setItem(LocalStorageKeys.API_URL, url)
      : window.localStorage.removeItem(LocalStorageKeys.API_URL)
  },
  getInventoryOnHandTableColumns: (): TInventoryOnHandColumns | null => {
    const iohTableColumns = window.localStorage.getItem(
      LocalStorageKeys.INVENTORY_ON_HAND_TABLE_COLUMNS,
    )
    return iohTableColumns && JSON.parse(iohTableColumns)
  },
  setInventoryOnHandTableColumns: (columns: TInventoryOnHandColumns) =>
    window.localStorage.setItem(
      LocalStorageKeys.INVENTORY_ON_HAND_TABLE_COLUMNS,
      JSON.stringify(columns),
    ),
  setInventoryLogFilters: (filters: {}) =>
    window.localStorage.setItem(
      LocalStorageKeys.INVENTORY_LOG_SUMMARY_FILTERS,
      JSON.stringify(filters),
    ),
  getInventoryLogFilters: () => {
    const ilsFilters = window.localStorage.getItem(LocalStorageKeys.INVENTORY_LOG_SUMMARY_FILTERS)
    return ilsFilters && JSON.parse(ilsFilters || '{}')
  },
  getReportQueryFilter: () => {
    return JSON.parse(window.localStorage.getItem(LocalStorageKeys.REPORT_QUERY_FILTER) || '{}')
  },
  setReportQueryFilter: (queryObject: Record<string, unknown>) => {
    window.localStorage.setItem(LocalStorageKeys.REPORT_QUERY_FILTER, JSON.stringify(queryObject))
  },

  setLoyaltyPointsMultiplier: async (companyId: number) => {
    const companyWithMetaData: ICompanyWithMetaDataContract<MetaDataType.INTEGER> =
      await API.getCompanyById(companyId, true)

    const loyaltyPointsMultiplier = companyWithMetaData.metaData[MetaKeys.loyaltyPointsMultiplier]
    if (isNil(loyaltyPointsMultiplier)) {
      LocalStorage.setItem(LocalStorageKeys.LOYALTY_POINTS_MULTIPLIER, '100')
      return
    }

    LocalStorage.setItem(
      LocalStorageKeys.LOYALTY_POINTS_MULTIPLIER,
      loyaltyPointsMultiplier.toString(),
    )
  },

  getLoyaltyPointsMultiplier: () => {
    const loyaltyPointsMultiplier = window.localStorage.getItem(
      LocalStorageKeys.LOYALTY_POINTS_MULTIPLIER,
    )
    return loyaltyPointsMultiplier ? parseInt(loyaltyPointsMultiplier) : 100
  },

  setEventLogParams: (eventTopic: EventsV2.EventModels.EventTopics, key: string, value: string) => {
    const stringEventLogParams =
      window.sessionStorage.getItem(LocalStorageKeys.EVENT_LOG_PARAMS) || ''

    const eventLogParams = isJsonString(stringEventLogParams)
      ? JSON.parse(stringEventLogParams)
      : {}

    eventLogParams[eventTopic] = {
      ...eventLogParams[eventTopic],
      [key]: value,
    }

    window.sessionStorage.setItem(LocalStorageKeys.EVENT_LOG_PARAMS, JSON.stringify(eventLogParams))
  },

  getEventLogParams: (eventTopic: EventsV2.EventModels.EventTopics, key: string) => {
    const stringEventLogParams =
      window.sessionStorage.getItem(LocalStorageKeys.EVENT_LOG_PARAMS) || '{}'

    const eventLogParams = isJsonString(stringEventLogParams)
      ? JSON.parse(stringEventLogParams)
      : {}

    const value = eventLogParams[eventTopic]?.[key] || ''

    return isJsonString(value) ? JSON.parse(value) : undefined
  },
}
